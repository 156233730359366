import axios, { AxiosRequestConfig } from 'axios';
import { format } from 'date-fns';

export const urlPlusPagination = (defaultUrl: string, paginationIndex?: number | null, paginationUrl?: string): string => {
  // Returns url with pagination matching django pagination (If needed)
  if (paginationUrl) {
    return paginationUrl;
  }

  if (paginationIndex) {
    return `${defaultUrl}?page=${paginationIndex}`;
  }

  return defaultUrl;
}

export const fetcher = (url: string, config: AxiosRequestConfig) => axios.get(url, config).then((res) => res.data);

// export const fetcherFull = (url: string, config: AxiosRequestConfig) => axios({...config, url}).then((res) => res.data);
export const fetcherFull = (url: string, config: AxiosRequestConfig) => axios.get(url, config).then((res) => res.data);


export const createDateStringForParam = (date: Date): string => {
  return format(date, "yyyy-MM-dd HH:mm");
}