import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Task } from "../../store/task";
import {
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { parse } from "date-fns";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../DatePicker";
import { POMODORO_TYPE, PomoCategories } from "./config";
import { useParams } from "react-router";
import { BoardFull } from "../../store/boardList";
import { Project, useProject } from "../table/projectSWR";
import {
  createProjectsList,
  projectIdToName,
  listToAutoCompleteOptions,
} from "../../select/selectSWR";
import { useCategory } from "../../swr/categorySWR";
import { FormInstance } from "antd/lib/form";
import { createKanbanBoard_task } from "../../util/swrCRUD";
import log from "loglevel";
import {
  columnTaskSlice,
  createColumnTask_Task,
  updateColumnTask_Task,
} from "../../store/column";
import { getTask_in_columnTask_byId } from "./utils";
import { projectNameToId } from "../../select/selectSWR";

/*

AutoComplete note for future.
This is quite complex with data flow and checking without note it'll be hard to workout again.

AutoComplete has "datasource" used for generating it's list.
  This is generated through my functions.
    listToAutoCompleteOptions makes it into antD auto format.
    pass in "currentItems" to remove so use only sees categories user can choose. I.e already selected python. Python is removed from auto list.

AutoComplete onChange and onSelect work under seperate system.
  onSelect is for dropdown. It will clear when user inputs. This system by itself works fine.
    I explicted set userInput.category as "" aka empty string.
  onChange is for input box AutoComplete comes with. It will clear when I press enter
    Using custom input (AutoCompmlete uses <Input /> by default but I add it again) due to onChange of AutoComplete giving value.
    With Normal <Input /> I add in onChange
      1. Check for duplicate and ignore. Valudation handled by form.
      2. OnEnter if it doesn't exist on categories it should
        Create on frontend
        Create on bankend.
        Add to SWR for caching

Form.Item will do custom validation by watching onChange


*** Super important !!! ***
Form works differently. Standard onChange, value= will not work as expected.
Input components are controlled by form. Have to use form's special setter and getter.

fuuu, Form has it's own getter and setter. value={state.task} doesn't work because it's overrideen by form setting value.

In addition to above I also add
  form.setFieldsValue({task: value})

see https://stackoverflow.com/questions/62855272/setting-the-default-value-inside-the-input-field-in-the-antd-library-after-calli
for full explaination for antd flow + mount issue.

** Another form info - initial state **
Needs to be set in 2 places

initialFormValues() contains my logics for default clean values (aka form reset. Reset with default values.)
It will set my state. Antd form state.

*/

/*

ModalInput was original. Created duplicate with ModalInputOri in the end.

This inherted is becoming mess to accomidate for change I have to do far down the line to fix a bug.
I think this is powerful but I need to re-think and create inheritence since it's become mess.
I am doing this with low brain power at the moment tired so not best solution coming out for sure,
and changes have long long break.

*/

const { TextArea } = Input;

export interface ModalInputBase {
  category?: string; // in AutoComplete Box
  description?: string;
  type?: PomoCategories;
  archived?: boolean;
}

export interface ModalInputOri extends ModalInputBase {
  project?: string;
  task?: string;
  date_start?: Date | null;
  categories: string[]; // in Tags
}

export interface ModalInput extends ModalInputBase {
  task?: string;
  date_start?: Date | null;
  categories: string[]; // in Tags
}
export interface MondalInputExtra extends ModalInputBase {
  project?: string;
  task: string;
  date_start?: Date | null;
  categories?: string[];
}
export interface MondalInputB4Post extends ModalInput {
  project?: string;
  task: string;
  date_start?: Date | null;
}
export interface MondalInputPost extends ModalInputBase {
  project?: string;
  name: string;
  date_start?: Date | null;
  categories: string[];
}
export interface MondalInputB4Put extends ModalInput {
  project?: number;
  task: string;
  date_start: Date;
}
export interface MondalInputB4Create extends ModalInput {
  project?: string;
  task: string;
  date_start: Date;
}
export interface MondalInputPut extends ModalInputBase {
  project?: number;
  name: string;
  categories: string[];
  date_start: string;
}

const initialUserInput = (extra?: MondalInputExtra) => {
  const initial: ModalInputOri = {
    project: "",
    category: "",
    categories: [],
    date_start: new Date(),
    type: undefined,
    archived: false,
    // column: this.props.columnId,
  };
  return extra ? { ...initial, ...extra } : initial;
};

interface Props {}

export const TaskModal: React.FC<Props> = () => {
  // project type? useState<Project>. Here init if there are data already?

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);
  const appLoading = useSelector((state: RootState) => state.appLoading);

  // Used for boardName, default choices field of Select (Pomodoro type)
  const { id } = useParams();
  const boardId = Number(id);
  const boardListFull = useSelector((state: RootState) => state.boardListFull);
  const curKanbanBoard = boardListFull[boardId] as BoardFull;
  const curPomodoroType = POMODORO_TYPE[curKanbanBoard.name] as PomoCategories;

  // columnTask to get Task data
  const columnTask = useSelector((state: RootState) => state.columnTask);

  // Create list of project for AutoSelect
  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError,
  } = useProject();
  const projects = projectData?.results;
  const projectsList = projectIsLoading ? [] : createProjectsList(projects);

  // Create list of project for AutoSelect
  const { data: categoryData, isLoading: categoryIsLoading } = useCategory();
  const categoriesList = categoryIsLoading ? [] : categoryData.results;

  // Decide initially if empty or filled in version (Add or Edit)
  const task = Boolean(appGlobalUser.modalColumnId)
    ? getTask_in_columnTask_byId(
        columnTask[appGlobalUser.modalColumnId],
        appGlobalUser.modalTaskId
      )
    : undefined;

  const initialUserInputExtra: MondalInputExtra = task
    ? {
        // project: task.project ? String(task.project) : undefined, // rm this line once bug of sending to backend if fixed. Left for convience of debugging although not sure if this is the cause.
        project: task.project
          ? projectIdToName(projects, task.project)
          : undefined, // TODO: work around. use project Id to get name.
        task: task.name,
        categories: task.categories,
        description: task.description ? task.description : "",
        type: task.type,
        date_start: parse(task.date_start, "yyyy-MM-dd", new Date()),
        archived: task.archived,
      }
    : {
        task: "",
        description: undefined,
        type: curPomodoroType,
        date_start: new Date(),
      };

  const [userInput, setUserInput] = useState<ModalInputOri>(
    initialUserInput(initialUserInputExtra)
  );

  const handleAutoCompleteChanges = (value: string) => {
    console.log("handleAutoCopmleteChanges");
    if (!userInput.categories.includes(value)) {
      console.log("!userInput.categories.includes(value) is TRUE");
      setUserInput({
        ...userInput,
        // category: "",
        categories: [...userInput.categories, value],
      });
    }
  };

  // handleCancel. handleOk. Add those in from function above

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const onCreate = (values: any) => {
  //   console.log("Received values of form: ", values);
  //   dispatch(appGlobalUserSlice.actions.modalVisible(false));
  // };

  const renderTaskCategories = (categories: string[]) => {
    const task_categoryTags = categories.map((category) => {
      return (
        <Tag
          key={`task-${category}`}
          color="geekblue"
          closable
          onClose={() => {
            // Remove category from array
            const categories = userInput.categories.filter((tag) => {
              return tag !== category;
            });
            // Update userInput state
            setUserInput({
              ...userInput,
              categories: categories,
            });
          }}
        >
          {category}
        </Tag>
      );
    });
    return task_categoryTags;
  };

  const initialFormValues = () => {
    /* Logic for resetting my state and antd's state */
    // console.log('setting field value + my state')
    form.setFieldsValue(initialUserInput(initialUserInputExtra));
    setUserInput(initialUserInput(initialUserInputExtra));
  };

  // https://stackoverflow.com/questions/62855272/setting-the-default-value-inside-the-input-field-in-the-antd-library-after-calli
  // Ant Design is taking over control - so you don't have to set value={name} and onChange.
  //You want to set the values AFTER the component is created. So to do that you need
  // Form values need to get set on rendering component initially
  useEffect(() => {
    initialFormValues();
  }, [appGlobalUser.modalTaskId, appLoading.taskModal]);

  const renderDevMenus = (form: FormInstance) => {
    // Only console logs
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.REACT_APP_ENV_DEBUG_BARS === "true"
    ) {
      // console.log(form.getFieldValue("project"));
      console.log("form.getFieldsValue()");
      console.log(form.getFieldsValue());

      // console.log(form)

      // console.log(projects);
      // console.log(projectIdToName(projects, 1));
      // console.log(listToAutoCompleteOptions(projectsList))
    }
  };

  return (
    <div>
      {/* {console.log(userInput)} */}
      {/* {renderDevMenus(form)} */}
      <Modal
        forceRender={true} // possibily need in form
        destroyOnClose={true} // Don't destory so what I set here onClose stay (my manual clear). Once form exist it doesn't trigger useEffect. onClose to "reset" values.
        title={appGlobalUser.modalTitle}
        centered={true}
        visible={appGlobalUser.modalVisible}
        onCancel={() => {
          dispatch(appGlobalUserSlice.actions.modalTitle(""));
          dispatch(
            appGlobalUserSlice.actions.modalVisible(!appGlobalUser.modalVisible)
          );
          // dispatch(appGlobalUserSlice.actions.modalColumnId(undefined));
          dispatch(appGlobalUserSlice.actions.modalTaskId(undefined));
          // Reset my state and antd form state.
          initialFormValues();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              log.info("validateFields passed");

              // Format data to be used on backend
              const data = userInput;

              // Send off api event. This response is very fast so don't need to add seperately (which will be missing id, timestamp etc so easier to go with this flow)

              if (appGlobalUser.modalTaskId) {
                // There is task, we're modifying. var task should be something due to appGlobalUser.modalTaskId

                // I can't do ProjectName to ProjectID with validation it is needed to be done AFTER user has done input
                // and it should not change user's input in anyway. Here i AM extracting/changing user's input.
                // data = data.project?  : data

                const _data = data.project
                  ? {
                      ...data,
                      project: projectNameToId(projects, data.project),
                    }
                  : data;

                dispatch(
                  updateColumnTask_Task({
                    taskID: appGlobalUser.modalTaskId,
                    columnID: appGlobalUser.modalColumnId,
                    data: _data as MondalInputB4Put,
                  })
                );
              } else {
                // Creating new task
                // This has endpoint that allow different project to updateColumnTask hence data has different interface

                dispatch(
                  createColumnTask_Task({
                    columnID: appGlobalUser.modalColumnId,
                    data: data as MondalInputB4Create,
                  })
                );
              }

              // Reset AntD Form state, Reset my state (also tracked seperately).
              // Might refactor to use AntD form state, it takes over html component and component show AntD form state.
              initialFormValues();

              // Hide modal, clear modalColumnId
              dispatch(appGlobalUserSlice.actions.modalVisible(false));
              dispatch(appGlobalUserSlice.actions.modalColumnId(undefined));
              dispatch(appGlobalUserSlice.actions.modalTaskId(undefined));
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          // initialValues={initialUserInput(initialUserInputExtra)}
          preserve={false}
        >
          <Form.Item className="mb-1" name="project">
            <AutoComplete
              style={{ width: 200 }}
              options={listToAutoCompleteOptions(projectsList) as any}
              placeholder="Project List"
              filterOption={(inputValue, option: any) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onChange={(value) => {
                setUserInput({ ...userInput, project: value });
                form.setFieldsValue({ project: value });
              }}
            />
          </Form.Item>
          <Form.Item
            className="mb-1"
            // label="Add Task"
            name="task"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please add a task",
              },
            ]}
          >
            <Input
              placeholder="Add Task"
              onChange={(e) => {
                setUserInput({ ...userInput, task: e.target.value });
                form.setFieldsValue({ task: e.target.value });
              }}
              // value={userInput?.task}
            />
          </Form.Item>
          <div className="flex flex-row">
            <Form.Item
              className="mb-1"
              name="category"
              validateTrigger={["onChange"]}
              rules={[
                {
                  validator: async (_, category: string) => {
                    if (userInput.categories.includes(category)) {
                      return Promise.reject("Already added");
                    }
                  },
                },
              ]}
            >
              <AutoComplete
                style={{ width: 200 }}
                options={
                  listToAutoCompleteOptions(
                    categoriesList,
                    userInput.categories
                  ) as any
                }
                filterOption={(inputValue, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                placeholder="Add a category for the task"
                onSelect={(value) => {
                  // Don't add duplicate
                  if (!userInput.categories.includes(value)) {
                    console.log(
                      "!userInput.categories.includes(value) is TRUE"
                    );
                    setUserInput({
                      ...userInput,
                      category: "",
                      categories: [...userInput.categories, value],
                    });
                    form.setFieldsValue({ category: "" });
                  }
                }}
              >
                <Input
                  onChange={(e) => {
                    // TODO: NEED TO ADD SWR Add, SWR Post flow for non-existing categories.
                    setUserInput({ ...userInput, category: e.target.value });
                    form.setFieldsValue({ category: e.target.value });
                  }}
                />
              </AutoComplete>
            </Form.Item>
            <div className="ml-2">
              {renderTaskCategories(userInput.categories)}
            </div>
          </div>
          <Divider />
          <Form.Item
            className="mb-1"
            // label="Add Task"
            name="description"
          >
            <TextArea
              placeholder="Description"
              rows={4}
              onChange={(e) => {
                setUserInput({ ...userInput, description: e.target.value });
                form.setFieldsValue({ description: e.target.value });
              }}
            />
          </Form.Item>
          <div className="flex flex-row">
            <Form.Item
              className="mb-1"
              name="date_start"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker
                className="ml-1"
                format="YY-MM-DD"
                onChange={(val) => {
                  setUserInput({ ...userInput, date_start: val });
                  form.setFieldsValue({ date_start: val });
                }}
                showToday={true}
                // value={userInput.date_start}
                // defaultValue={moment()}
              />
            </Form.Item>

            <Form.Item
              className="mb-1"
              name="type"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Select
                className="ml-2" // can't change width through class (why!!!). Need style.
                style={{ minWidth: 100 }}
                onChange={(value: PomoCategories) => {
                  setUserInput({ ...userInput, type: value });
                  form.setFieldsValue({ type: value });
                }}
              >
                {Object.keys(POMODORO_TYPE).map((boardTitle: string) => {
                  const pomoType = POMODORO_TYPE[boardTitle];
                  return (
                    <Select.Option key={pomoType} value={pomoType}>
                      {pomoType}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item className="mb-1" name="archived">
              <Tooltip title="Complete! Don't show in this column. (Still will be related to this column). If selected arv is true.">
                <Switch
                  className="ml-3" // Inputbox on left is pushing into here. Need to push Switch away little bit.
                  // size="default"
                  onChange={(value) => {
                    setUserInput({ ...userInput, archived: value });
                    form.setFieldsValue({ archived: value });
                  }}
                />
              </Tooltip>
            </Form.Item>
          </div>

          <Divider />
        </Form>
      </Modal>
    </div>
  );
};
