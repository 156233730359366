import React, { useState } from "react";
import { Timeline, Spin, Tag, Row, Col, Pagination } from "antd";
import { useMood, Mood } from './moodSWR';
import { MoodChart } from './MoodChart';
import { parseISO, format } from 'date-fns';
import { CenteredVerHor } from "../style/CenteredVerHor";

interface Props {}

export const MoodList: React.FC<Props> = () => {
  const [pageIndex, setPageIndex] = useState<number>(1);

  const { data, isLoading, isError } = useMood(pageIndex);

  const count = data?.count;
  const results = data?.results;
  const moods = results;


  const renderCategories = (categories: string[]) => {

    let moodTags = categories.map((category: string) => {
      return <Tag key={`mood-${category}`} color="cyan">{category}</Tag>
    })

    return moodTags
  }

  const renderFeelings = (feelings: string[]) => {

    let feelingTags = feelings.map((feeling: string) => {
      return <Tag key={`feeling-${feeling}`} color="geekblue">{feeling}</Tag>
    })

    return feelingTags
  }

  const renderMoodList = (moods: Mood[]) => {
    const chartComponents = moods.map((mood: Mood, index: number) => {

      const date = parseISO(mood.date);

      return (
        <Timeline.Item key={index}>
          <Row>
            <Col md={6}>
              {/*<div style={styles.cardStyle}>*/}
              {format(date, "EEEE yyyy-MM-dd")} - {format(date, "hh:mm:ss a")}
            </Col>
            <Col md={6}>
              <MoodChart data={mood} />
            </Col>
            <Col md={6}>
              <div>{renderCategories(mood.categories)}</div>
              <div className="mt-1">{renderFeelings(mood.feelings)}</div>
            </Col>
            <Col md={6}>
              <div>{mood.comment}</div>
            </Col>
          </Row>
        </Timeline.Item>
      )
    })
    return chartComponents;
  }

  if (isLoading) return (
      <CenteredVerHor>
        <Spin size='large'/>
        <div>Loading mood data...</div>
      </CenteredVerHor>
    )

  if (isError) return (
    <CenteredVerHor>
      {/* Sadly doesn't actually freeze spinning but disapears */}
      <Spin size='large' spinning={false}/>
      <div>Whaaat? There is an error</div>
    </CenteredVerHor>
    )

  return (
    <div>
      <Timeline>
        {renderMoodList(moods)}
        <Timeline.Item></Timeline.Item>
      </Timeline>
      <Row>
        <Pagination
          size="small"
          total={count}
          current={pageIndex}
          defaultPageSize={30} // Currently Manually matching backend pagination. MoodResultsSetPagination, page_size
          pageSizeOptions={['30']}
          onChange={(pageIndex) => setPageIndex(pageIndex)}
          showQuickJumper
        />
      </Row>
      {/* moods: */}
      {/* <pre>{JSON.stringify(moods, null, 2)}</pre> */}
    </div>
  );
};
