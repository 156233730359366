import useSWR from 'swr';
import { URLS } from '../../UrlConst';
import { DjangoPagination } from '../../store/typesShared';
import { urlPlusPagination, fetcher } from '../../util/swrHelper';
import log from 'loglevel';


export const lifeLabels: {[key: string]: string} = {
  loveRelationship: "Love/Relationship",
  healthFitness: "Health/Fitness",
  careerEducation: "Career/Education",
  personalDevelopment: "Personal Development",
  family: "Family",
  friends: "Friends",
  social: "Social",
  funRecreation: "Fun/Recreation",
  finance: "Finance",
};

export interface BaseLife {
  [key: string]: any;

  loveRelationship: number;
  healthFitness: number;
  careerEducation: number;
  personalDevelopment: number;
  family: number;
  friends: number;
  social: number;
  funRecreation: number;
  finance: number;
  comment: string;
}

// For creating from Front-end app
export interface LifeAddEmpty extends BaseLife {
  date: Date | null;
}

// Data from backend app
export interface Life extends BaseLife {
  date: string;
  id?: number;
  timestamp?: string;
  owner?: number;
}

export interface LifeResponse extends DjangoPagination {
  results: Life[];
}

export const useLife = (paginationIndex?: number | null, paginationUrl?: string): {data: LifeResponse, isLoading: boolean, isError: boolean} => {

  const url = urlPlusPagination(URLS.life, paginationIndex, paginationUrl);
  log.debug(url);

  const { data, error } = useSWR(url, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}