import React from 'react';
import { VictoryChart, VictoryBar, VictoryTheme, VictoryAxis, VictoryContainer, VictoryLabel } from 'victory';
import chroma from 'chroma-js';

import { Mood } from './moodSWR';


const getColour1 = chroma.scale(['#ff392e', '#ffb2ae']).padding(0).colors(4)
const getColour2 = chroma.scale(['#baddbf', '#76bb7f']).padding(0).colors(5)
const getColour = getColour1.concat(getColour2)

interface Props {
  data: Mood;
}

export const MoodChart: React.FC<Props> = ({ data }) => {

  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        height={120} // 100 causes this issue https://github.com/FormidableLabs/victory-native/issues/132
        containerComponent={<VictoryContainer responsive={true} />}
      >
        <VictoryBar
          horizontal
          data={[
            {x: 1, y: [data.mood]},
          ]}

          // label
          labels={[data.mood]}
          labelComponent={<VictoryLabel dx={-20} dy={0} style={{fontSize: 17}}/>} // Label offset

          domain={{x: [0, 10], y: [-3, 3]}}
          style={
            {
              data:
                {
                  fill: getColour[data.mood-1],
                  width: 60,
                }
            }
          }
          animate={{duration: 500, onLoad: {duration: 500}}}
        />
        {/* Axis Doc: https://formidable.com/open-source/victory/guides/layout/*/}
        <VictoryAxis
          dependentAxis
          domain={[-10, 10]}
          height={100}
          theme={VictoryTheme.material}
          standalone={false}
          style={{
            axis: {stroke: "none"},
            ticks: {
              padding: 0,
            },
            tickLabels: {fontSize: 17, padding: 15}
          }}
          tickValues={[-10, 0, 10]}
        />
      </VictoryChart>
    </div>
  );
};
