import React from "react";
import { MainLayout } from "./common/MainLayout";
import { RecordTable } from './table/RecordTable';

interface Props {}

export const RecordListContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <RecordTable />
    </MainLayout>
  );
};
