import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import log from 'loglevel';
import { URLS } from '../UrlConst';
import { DjangoPagination } from './typesShared';

const initialState: string[] = [];

export interface CategoryResponse extends DjangoPagination {
  results: string[];
}

export const _fetchCategories = createAsyncThunk(
  'category/_fetch',
  async () => {
    const response = await axios.get(URLS.category);
    return response.data;
  }
)

export const categorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`)
      return []
    }
  },
  extraReducers: {
    // [_fetchCategories.fulfilled.type]: (state, action: PayloadAction<{'categories': string[];}>) => {
    [_fetchCategories.fulfilled.type]: (state, action: PayloadAction<CategoryResponse>) => {
      log.info(`${action.type} - ${URLS.category}`)
      log.debug(action.payload)
      state.push(...action.payload.results);
    },
    [_fetchCategories.pending.type]: (_state, action) => {
      log.info(action.type)
    }
  }
})