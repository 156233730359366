import React from "react";
import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { TaskComponent } from "./Task";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { BoardFull } from "../../store/boardList";
import log from "loglevel";
import { Task } from "../../store/task";
import { CenteredVerHor } from "../style/CenteredVerHor";
import { arrloadingChecker } from "../../select/appLoading";
import { TaskModalAdd } from "./TaskModalAdd";
import { useProject } from "../table/projectSWR";
import { projectNameToId } from "../../select/selectSWR";

interface Props {
  columnNameId: string;
  columnId: number;
  index: number;
}

export const Column: React.FC<Props> = ({ columnNameId, columnId, index }) => {
  // Future: Optimize ajax of project. This is so ugly having every column get it's own project. I think SWR latest has big changes including caching and fixing this issue.
  // Check1: Ok checked log on django API and there's only 1 call to project API so I think optimization is happening with SWR already behind the scene!!! :D. Check again to make sure 100%
  const {
    data: projectData,
    isLoading: projectDataIsLoading,
    isError: projectDataIsError,
  } = useProject();
  const projects = projectData?.results;

  const { id } = useParams();
  const appLoading = useSelector((state: RootState) => state.appLoading);
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);

  const boardListFull = useSelector((state: RootState) => state.boardListFull);
  const curKanbanBoard = boardListFull[Number(id)] as BoardFull;
  const { columns } = curKanbanBoard;

  // If column is indexed by id does not need ugly [0].
  const curColumn = columns.filter((column) => column.id === columnId)[0]; // It will find, since id exist. Get first one (there will be only 1)

  const columnTask = useSelector((state: RootState) => state.columnTask);
  const curColumnTask = columnTask[columnId]; // Contains all tasks for this (currently rendering) column

  // using useCallback is optional
  // const onBeforeCapture = useCallback(() => {
  //   /*...*/
  // }, []);
  // const onBeforeDragStart = useCallback(() => {
  //   /*...*/
  // }, []);
  // const onDragStart = useCallback(() => {
  //   /*...*/
  // }, []);
  // const onDragUpdate = useCallback(() => {
  //   /*...*/
  // }, []);

  // const onDragEnd = useCallback(
  //   (result) => {
  //     // the only one that is required
  //     if (!result.destination) {
  //       return;
  //     }

  //     if (result.destination.index === result.source.index) {
  //       return;
  //     }

  //     const tasksNew: TaskInterface[] = reorder(
  //       tasks,
  //       result.source.index,
  //       result.destination.index
  //     );

  //     setTasks(tasksNew);
  //   },
  //   [tasks]
  // );

  // const onDragEnd = (result: DropResult) => {
  //   console.log("column.tsx onDragEnd result");
  //   console.log(result);

  //   if (!result.destination) {
  //     return;
  //   }

  //   if (result.destination.index === result.source.index) {
  //     return;
  //   }
  // };

  // console.log(tasks);

  // Loader //
  const allColumnTaskLoaded = arrloadingChecker(
    Object.values(appLoading.columnTask)
  );
  log.debug(`allColumnTaskLoaded: ${allColumnTaskLoaded}`); // Checks to see boardListFull loaded, which has column information

  if (allColumnTaskLoaded || curColumnTask === undefined) {
    return (
      <CenteredVerHor>
        {/* TODO!: Re-enable. Why is it complaining about /> ??? */}
        {/* <Spin size="large" /> */}
        <div>Loading Column Tasks (Tasks for a column)</div>
      </CenteredVerHor>
    );
  }

  log.info(
    `Loading column ${curColumn.id}. Tasks on this column of ${columnId}`
  );

  // TODO: Index need to be consecutive. Index will be skipped if null... need another way.
  // Maybe process to remove task from list before.
  // const renderTasks = (dropProvided: DroppableProvided) => {
  //   const curColumnTaskToShow = curColumnTask.filter((task: Task) => task.archived!==appGlobalUser.hideArchived)
  //   return curColumnTaskToShow.map((task: Task, index: number) => {
  //     return (
  //       <div
  //         ref={dropProvided.innerRef}
  //         key={task.id}
  //        >
  //          Title
  //         <TaskComponent task={task} index={index} key={task.id} />
  //         DropZone-[below]InnerQuoteList
  //         {dropProvided.placeholder}
  //       </div>
  //     )
  //   });
  // };

  // Loop is structured different so will look different to match it's innerRef places. This dropProvided is not looped.
  const renderTasks = () => {

    // prepare 'project' for filtering. It is slightly awkward due to columTask using projectID and Select input box using pure name
    
    // added null to satisfy task.project type
    const kanbanProjectFilterIDs: Array<number | undefined | null> =
      appGlobalUser.kanbanProjectFilter.map((projectName) =>
        projectNameToId(projects, projectName)
      );

    // Filter Task in columns to show
    const curColumnTaskToShow = curColumnTask
      .filter((task: Task) => task.archived !== appGlobalUser.hideArchived)
      .filter((task: Task) =>
        kanbanProjectFilterIDs.length
          ? kanbanProjectFilterIDs.includes(task.project)
          : true  // When empty show everything and don't filter by project.
      );

    // console.log(projects)
    // console.log(appGlobalUser.kanbanProjectFilter);
    // console.log(kanbanProjectFilterIDs);
    // console.log(curColumnTaskToShow);

    // generate task
    return curColumnTaskToShow.map((task: Task, index: number) => {
      return <TaskComponent task={task} index={index} key={task.id} />;
    });
  };

  return (
    <div>
      <Draggable draggableId={columnNameId} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <div
            // w-56
            // This is where I can control whole column size
            className={`
              flex flex-col items-center
              w-56 h-full m-3 ${
                snapshot.isDragging ? "bg-gray-500" : "bg-gray-300"
              }
              border-solid border rounded-sm ${
                snapshot.isDragging ? "bg-gray-300" : "border-gray-200"
              }
              `}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              className="w-full"
              // This is where I can choose to drag. Need w-full to make element draggable for whole width.
              {...provided.dragHandleProps}
            >
              <div
                className={`
                pt-2 text-2xl font-medium text-center
                hover:bg-gray-400 transition duration-150`}
                title={`id: ${curColumn.id}`}
              >
                {curColumn.title}
              </div>
            </div>

            <hr className="my-2 border-solid border border-gray-600 w-11/12" />
            {/* Column whole. Bottom part is inner droppable nested */}
            {/* Up to here, same structure. */}
            {/* Below is like 'QuoteList' on their storyboard */}
            <Droppable
              droppableId={String(curColumn.id)}
              // droppableId={String(`columnInside-DroppableId-${columnNameId}`)}
              direction="vertical"
              type="task"
            >
              {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot
              ) => (
                // below div is Wrapper in quote-list
                <div
                  className={`
                  flex flex-col w-full items-center
                  h-full m-3 ${
                    // dropSnapshot.draggingFromThisWith is where it starts.
                    // dropSnapshot.draggingFromThisWith ? "bg-gray-500" : "bg-gray-300"

                    // dropSnapshot.isDraggingOver is which column task is on.
                    // Only heightlight when moving tasks. Otherwise bg is over column's style and overrides bg colour
                    // for column drag.
                    dropSnapshot.isDraggingOver ? "bg-gray-500" : ""
                  }
                  `}
                  // className=""
                  ref={dropProvided.innerRef} //Put under each task?. That is structure in Storyboard
                  {...dropProvided.droppableProps}
                >
                  {/* top */}
                  {renderTasks()}
                  {dropProvided.placeholder}
                  {/* bottom */}
                </div>
              )}
            </Droppable>
            <TaskModalAdd columnId={curColumn.id} />
          </div>
        )}
      </Draggable>
    </div>
  );
};

// {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
// vs
// {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
// is just name change?
