import useSWR from "swr";
import { URLS } from "../../UrlConst";
import { DjangoPagination, PomoCategories } from "../../store/typesShared";
import { urlPlusPagination, fetcher } from "../../util/swrHelper";
import log from "loglevel";
import { AxiosRequestConfig } from "axios";
import { Task, TaskResponse } from "../../store/task";

// "id": 540,
// "name": "Custom Form tryout",
// "date_start": "2020-09-16",
// "timestamp": "2020-09-16T13:35:05.180516",
// "description": null,
// "project": 31,
// "task_order": 1,
// "goal": "0.00",
// "record_count": null,
// "record_goal_weekly": 0.0,
// "record_goal_max": 0.0,
// "type": "Work",
// "categories": [
//     "Programming",
//     "Python"
// ],
// "owner": 1,
// "archived": false

export const useTask = (
  config?: AxiosRequestConfig
): { data: TaskResponse; isLoading: boolean; isError: boolean } => {
  const url = URLS.task;
  log.debug(url);

  log.debug(config);

  const { data, error } = useSWR([url, config], fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

// For footer. Shows task for given board, matches http://127.0.0.1:8018/api/kanbanboard/1/tasks
export const useBoardTask = (
  boardId: string, // come in from of id, e.g 1
  config?: AxiosRequestConfig
): {
  data: TaskResponse;
  isLoading: boolean;
  isError: boolean;
  isValidating: boolean;
} => {
  // rules of task require this to always be called (unconditionally and outside of hook)
  // even though "boardTask" for footer purpose it's used for always call something

  // Call "All tasks (non archived param) by default"
  const url = boardId
    ? `${URLS.boardListFull}/${boardId}/tasks?page_size=100000`
    : `${URLS.task}?page_size=100000`;
  log.debug(url);
  log.debug(config);

  const { data, error, isValidating } = useSWR([url, config], fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
  };
};
