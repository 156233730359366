import React, { useState } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { Modal } from "antd";
import { Task } from "../../store/task";
import { taskId_to_taskDraggableId } from "./utils";
import { TaskModal } from "./TaskModal";
import { useDispatch, useSelector } from "react-redux";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
import { RootState } from "../../store/store";

interface Props {
  task: Task;
  index: number;
  // index?: any;
}

// Conflicted with Task Interface so renamed.
export const TaskComponent: React.FC<Props> = ({ task, index }) => {
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);

  return (
    <>
      <Draggable draggableId={taskId_to_taskDraggableId(task.id)} index={index}>
        {(
          dragProvided: DraggableProvided,
          dragSnapshot: DraggableStateSnapshot
        ) => (
          <div
            className={`flex flex-row w-full justify-center`}
          >
            <div
              className={`
            flex flex-row
            w-11/12 mb-3 p-1 ${
              dragSnapshot.isDragging ? "bg-purple-100" : "bg-gray-100"
            }
            ${dragSnapshot.isDragging && "border-2 border-purple-300"}
            ${
              dragProvided.draggableProps["data-rbd-draggable-id"] ===
              "task-" + appGlobalUser.globalSelectedTaskId
                ? "bg-indigo-200  border-2 border-indigo-300"
                : "bg-gray-100"
            }
            border-solid border border-gray-400 rounded-sm
            text-left
            `}
            
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            >
            {/* {console.log('task - provided')}
            {console.log(provided)} */}
              <div className={`w-2/12`}>
                {/* // TODO: Add color based on some meaningful way */}
                <div
                  // MT-1 to shift little bit. Doesn't actually programmatically match starting height of project.
                  className={`
                bg-orange-500
                hover:bg-orange-700
                  cursor-pointer
                  w-4 h-4
                  rounded-sm
                  mt-1
                  `}
                  onClick={() => dispatch(appGlobalUserSlice.actions.globalSelectedTaskId(task.id))}
                ></div>
              </div>
              <div
                className={`
                w-10/12 cursor-pointer pl-1 rounded-md
                ${task.id === appGlobalUser.globalSelectedTaskId ? 'hover:bg-indigo-300' : 'hover:bg-gray-200'}
                `}
                onClick={() => {
                  dispatch(appGlobalUserSlice.actions.modalTitle("Modify Task"));

                  dispatch(appGlobalUserSlice.actions.modalTaskId(task.id));
                  dispatch(
                    appGlobalUserSlice.actions.modalColumnId(task.columnId)
                  );

                  dispatch(
                    appGlobalUserSlice.actions.modalVisible(
                      !appGlobalUser.modalVisible
                    )
                  );
                }}
              >
                <div
                  className={`flex flex-col`}
                  title={task.description ? `${task.description}` : ""}
                >
                  <div className={`text-xs text-grey-500 align-middle`}>
                    {task.id}
                  </div>
                  <div>{task.name}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};
