import { configureStore, ThunkAction, Action, getDefaultMiddleware, StateFromReducersMapObject } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';

import counterSlice from '../example/counter/counterSlice';
import { taskSlice } from './task';
import { columnTaskSlice } from './column';
import { categorySlice } from './category';
import { recordSlice } from './record';
import { appLoadingSlice } from './appLoading';
import { projectSlice } from './project';
import { boardListSlice, boardListFullSlice } from './boardList';
import { appGlobalUserSlice } from './appGlobalUser';
import { appTaskModalSlice } from './appTaskModal';
import { authSlice } from './appAuth';

export const reducer = {
  // todos: todosSlice.reducer,
  // selectedTodo: selectedTodoSlice.reducer,
  counter: counterSlice.reducer,

  boardList: boardListSlice.reducer,
  boardListFull: boardListFullSlice.reducer,
  columnTask: columnTaskSlice.reducer,
  task: taskSlice.reducer,
  project: projectSlice.reducer,
  category: categorySlice.reducer,
  record: recordSlice.reducer,

  appLoading: appLoadingSlice.reducer,
  appGlobalUser: appGlobalUserSlice.reducer,

  auth: authSlice.reducer,

  appTaskModal: appTaskModalSlice.reducer,
};

// const middleware = [...getDefaultMiddleware(), logger]
// const middleware = [...getDefaultMiddleware()]  // depreciated
export const store = configureStore({
  reducer, //es6 shortcut, reducer: reducer
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // need to spread getDefaultMiddleware to be able to add on top of default. Otherwise only use logger. also es6 shortcut middleware: middleware
  // devTools: true,  // default true. Set to false for prod. (Or see below for env)
  devTools: process.env.NODE_ENV !== 'production', // Turn on for production only

  // TODO: Add preLoadedState for faster dev?
})


// https://redux-toolkit.js.org/usage/usage-with-typescript

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types


export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


// alternative to RootState
export type RootState2nd = StateFromReducersMapObject<typeof reducer>