import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,

  // Link,
  // Outlet,

  // useRoutes
} from 'react-router-dom';
import log from 'loglevel';

import './App.css';
import './assets/style/tailwind.css';

import { HomeContainer } from './components/HomeContainer';
import { Error404Container } from './components/page/Error404Container';
import { LoginContainer } from './components/auth/LoginContainer';
import { RecordListContainer } from './components/RecordTableContainer';
import { PomoQueryContainer } from './components/PomoQueryContainer';
import { KanbanContainer } from './components/KanbanContainer';

import { Counter } from './example/counter/Counter';
import { KanbanExample } from './example/KanbanExample';
import { LogoutContainer } from './components/auth/LogoutContainer';
import { DevContainer } from './components/page/DevContainer';
import { LoggerContainer } from './components/page/LoggerContainer';
import { fetchAll, fetchInitial, fetchInitialKanbanList } from './select/actionsHelper';
import { MoodContainer } from './components/MoodContainer';
import { LifeContainer } from './components/LifeContainer';
import { ProjectTableContainer } from './components/ProjectTableContainer';
import { TaskTableContainer } from './components/TaskTableContainer';
import { TimerExample } from './example/TimerExample';
import PrivateRoute from './components/auth/PrivateRoute';
import { RootState } from './store/store';
import { authSlice, _authUserAPI } from './store/appAuth';
import { InfoContainer } from './components/InfoContainer';
import { ConfigContainer } from './components/ConfigContainer';


/*
Every routes are "PrivateRoute" meaning routes like
<Route path="/" element={<HomeContainer />} />
are wrapped around for auth check.

Dev routes are not protected as they will not be accessible in live through env variable.
*/
const App:React.FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  // This will be nuked in useEffect if token is invalid through "_authUserAPI" check
  const localToken = localStorage.getItem('kanx-token');

  if (localToken) {
    // Initially set to localToken for whole system so it will trigger system to set states
    // This will trigger also check if token is correct.
    // If Token is incorrect system will nuke whole token states everywhere, causing this not to be triggered
    dispatch(authSlice.actions.setToken(localToken))
  }

  const isAuthenticated = localToken ? true : false

  useEffect(() => {
    auth.token && dispatch(_authUserAPI({token: auth.token}));
    isAuthenticated && dispatch(fetchInitialKanbanList());

    // Note: React gets stuck in loop if it's on above.
    // I think I had to have localToken outside of here.
    const localUser = localStorage.getItem('kanx-user');
    localUser && dispatch(authSlice.actions.setUser(JSON.parse(localUser)))

  }, [isAuthenticated, auth.token, dispatch])

  const renderDevMenu = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Not Production. Enabling log menu and logger');
      // log.setLevel("trace")
      log.setLevel("WARN");
      // log.setLevel("DEBUG");
      return (
        <Fragment>
          <Route path="/example/counter" element={<Counter />} />
          <Route path="/example/pomotimer" element={<TimerExample />} />
          <Route path="/example/kanban" element={<KanbanExample />} />
          <Route path="/dev" element={<DevContainer />} />
          <Route path="/log" element={<LoggerContainer />} />
        </Fragment>
        )
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <PrivateRoute
          path="/"
          component={HomeContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/main"
          component={HomeContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/kanban/:id"
          component={KanbanContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/record"
          component={RecordListContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/yourpomo"
          component={PomoQueryContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/projects"
          component={ProjectTableContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/tasks"
          component={TaskTableContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/mood"
          component={MoodContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/life"
          component={LifeContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/config"
          component={ConfigContainer}
          redirectLink='/config'
          isAuthenticated={isAuthenticated}
        />
        {renderDevMenu()}

        {/* <Route path="/admin" element={<HomeContainer />} /> */}

        {/* Redirect user back to home if authed */}
        <PrivateRoute
          path="/login"
          component={LoginContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />

        {/* Handle 404 */}
        <PrivateRoute
          path="*"
          component={Error404Container}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/logout"
          component={LogoutContainer}
          redirectLink='/login'
          isAuthenticated={isAuthenticated}
        />

        <Route path="/info" element={<InfoContainer />} />
      </Routes>
    </BrowserRouter>
  );
}



// function MyRoutes() {
//   let element = useRoutes([
//     { path: '/', element: <MainContainer /> },
//     // {
//     //   path: 'users',
//     //   element: <Users />,
//     //   children: [
//     //     { path: '/', element: <UsersIndex /> },
//     //     { path: ':id', element: <UserProfile /> },
//     //     { path: 'me', element: <OwnUserProfile /> },
//     //   ]
//     // }
//   ]);

//   return element;
// }

export default App;
