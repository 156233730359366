import log from 'loglevel';

export {}

interface UrlsProp {
  [key: string]: string;
}

const BASE_URL: string | undefined = process.env.REACT_APP_ENV_BASE_URL;

if (typeof process.env.REACT_APP_ENV_BASE_URL === undefined) {
  const errMsg = "Missing Backend URL in .env config file or varible wasn't added in build.";
  log.error(errMsg);
  throw errMsg;
}

export const URLS: UrlsProp  = {
  task: `${BASE_URL}/api/tasks`,
  taskUpdate: `${BASE_URL}/api/tasks/update`,
  category: `${BASE_URL}/api/category`,
  project: `${BASE_URL}/api/projects`,
  record: `${BASE_URL}/api/records`,   // for table of records list
  boardList: `${BASE_URL}/api/boardlist`,  // This is the entry point
  boardListFull: `${BASE_URL}/api/kanbanboard`,   // kanbanboard, fuller version of boardList
  columnTask: `${BASE_URL}/api/kanbanboard/column`,   // add /${id}/tasks to get full url

  order: `${BASE_URL}/api/kanbanboard`,   // Kanbanboard order. Add /${id}/order to get full url

  mood: `${BASE_URL}/api/mood`,
  life: `${BASE_URL}/api/life`,

  feeling: `${BASE_URL}/api/feeling`,
  moodCategory: `${BASE_URL}/api/mood-category`,

  pomoQuery: `${BASE_URL}/api/report/tasklistpomodoro`,  // for table of queried pomodoro

  login: `${BASE_URL}/api/auth/login`,
  userAPI: `${BASE_URL}/api/auth/user`,
}

