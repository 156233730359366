import React from "react";
import { MainLayout } from "./common/MainLayout";
import { ConfigTabs } from "./config/ConfigTabs";

interface Props {}

export const ConfigContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <div className="px-3">
        <ConfigTabs />
      </div>
    </MainLayout>
  );
};
