import React, { useState } from "react";
import { Spin, Table, Card, Badge, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useProject, Project } from "./projectSWR";
import { parseISO, differenceInDays, compareAsc } from "date-fns";
import { CenteredVerHor } from "../style/CenteredVerHor";
import DatePicker from "../DatePicker";
import { RangeValue } from "rc-picker/lib/interface";
import { AxiosRequestConfig } from "axios";
import { UsefulDates, ranges } from "../../util/dateUtils";
import { createDateStringForParam } from "../../util/swrHelper";
import { formatDuration } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
import { capitalizeFirstLetter } from "../../util/utils";

const { RangePicker } = DatePicker;

interface ProjectRow {}

// TODO: Add (generate) date filter. Add sorter. (Also for start time)
// used ID as default to order. Since new project (almost always) = newer time this is quick easy way to get right order of project from newest.
const columns: ColumnsType<ProjectRow> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    defaultSortOrder: "descend",
    sorter: (a: any, b: any) => a.id - b.id,
  },
  {
    title: "Project",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Date Start",
    dataIndex: "date_start",
    key: "date_start",
    defaultSortOrder: "ascend",
    sorter: (a: any, b: any) =>
      compareAsc(parseISO(a.date_start), parseISO(b.date_start)), // Have to do moment diff and return -1 or 0? Depending on positive or negative.
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Archived?",
    dataIndex: "archived",
    key: "archived",
    sorter: (a: any, b: any) => a.archived.localeCompare(b.archived),
    filters: [
      {
        text: "False",
        value: "False",
      },
      {
        text: "True",
        value: "True",
      },
    ],
    onFilter: (value, row: any) => row.archived.indexOf(value) === 0,
  },
  {
    title: "Categories",
    dataIndex: "categories",
    key: "categories",
    render: categories => (
      <>
        {categories.map((category: string) => {
          return (
            <Tag color={"geekblue"} key={category}>
              {category.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
];

const sumBadgeStyle = { backgroundColor: "#2db7f5" };

interface Props {}

export const ProjectTable: React.FC<Props> = () => {
  const usefulDates = new UsefulDates();

  // used for RangePicker
  // const [startDate, setStartDate] = useState<Date>(usefulDates.today);
  // const [endDate, setEndDate] = useState<Date>(usefulDates.tomorrow);

  // used for RangePicker Redux Hook version
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);
  const startDate = parseISO(appGlobalUser.projectTableStartDate);
  const endDate = parseISO(appGlobalUser.projectTableEndDate);

  // used for making param to be send to API server
  const [config, setConfig] = useState<AxiosRequestConfig>({
    params: {
      start: createDateStringForParam(startDate),
      end: createDateStringForParam(endDate),
    },
  });

  // TODO: Add date to get. Today with + - some hours to capture whole day hopefully.
  // TODO: Add default time that can be changed.
  const { data, isLoading, isError } = useProject(config);
  // const { results, next, previous, count} = data;

  const next = data?.next;
  const previous = data?.previous;
  const count = data?.count;
  const results = data?.results;
  const projects = results;

  // called projectWithBetterDates in records.
  const formatDataForTable = (_projects: Project[]) => {
    // Changes timestamp to better format.
    const projectsUpdated = _projects.map((project: Project) => {
      const _projectUpdated = {
        ...project,
        archived: capitalizeFirstLetter(String(project.archived)), // booleon to string and Cap first letter
      };
      return _projectUpdated;
    });
    return projectsUpdated;
  };

  const rangePickerChange = (date: RangeValue<Date>, dateString: string[]) => {
    // Return if there is no date (will not be case from antd? but seems to need this for typescript)
    if (!date) {
      return;
    }

    // Take moments time object
    const _startDate = date[0] as Date;
    const _endDate = date[1] as Date;

    // Change UI
    // setStartDate(_startDate);
    // setEndDate(_endDate);
    // Date is not serializable. Redux recommends serializable data.
    dispatch(
      appGlobalUserSlice.actions.projectTableStartDate(_startDate.toISOString())
    );
    dispatch(
      appGlobalUserSlice.actions.projectTableEndDate(_endDate.toISOString())
    );

    // Change to format backend (requires string of YYYY-MM-DD HH:MM[:ss[.uuuuuu]][TZ] format)
    setConfig({
      params: {
        start: createDateStringForParam(_startDate),
        end: createDateStringForParam(_endDate),
      },
    });

    // this.setState({
    //   date_start: date_start,
    //   end_time: end_time
    // }, () => { this.calculateDateDiff() })
  };

  // Using built in loading for table
  // if (isLoading)
  //   return (
  //     <CenteredVerHor>
  //       <Spin size="large" />
  //       <div>Loading projects data...</div>
  //     </CenteredVerHor>
  //   );

  if (isError)
    return (
      <CenteredVerHor>
        {/* Sadly doesn't actually freeze spinning but disapears */}
        <Spin size="large" spinning={false} />
        <div>Whaaat? There is an error</div>
      </CenteredVerHor>
    );

  ///
  // Process some display info before render.
  const differenceInDaysOf_DateRange = differenceInDays(endDate, startDate);
  const tableData = isLoading ? [] : formatDataForTable(projects);
  return (
    <div className="px-3">
      <h1 title="Filters date between start 4am to nextday 4am. Tasks falling between those times will be shown.">
        Your Day Project
      </h1>
      <div className="flex flex-row">
        <Card title="Pomo" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={0}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <Card title="Work" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={0}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <Card title="Goal" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={0}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <div className="flex flex-row items-center mx-2">=</div>
        <Card title="All" className="mx-1 w-32 text-center bg-indigo-200">
          <Badge
            title="Total"
            count={0}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
      </div>
      <div className="my-3">
        <Badge
          title={`This is ${formatDuration({
            days: differenceInDaysOf_DateRange,
          })}`}
          count={differenceInDaysOf_DateRange}
          style={{
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset",
          }}
          size="default"
          offset={[5, 0]}
        >
          <RangePicker
            ranges={ranges()}
            showTime
            format="yyyy-MM-dd HH"
            value={[startDate, endDate]}
            onChange={(date, dateString) => rangePickerChange(date, dateString)}
          />
        </Badge>
        {/* <Button onClick={() => console.log(new Date())}>Query</Button> */}
      </div>
      <Table
        rowKey="id"
        // dataSource={projects}
        loading={isLoading}
        dataSource={tableData}
        columns={columns}
        pagination={{
          position: ["bottomLeft", "topLeft"],
          pageSize: 100,
          showSizeChanger: false,
          hideOnSinglePage: true,
          simple: false,
          total: count,
          onChange: (page, pageSize) => {setConfig({...config, params: {...config.params, page:page}})}
        }}
        size="small"
        // bordered
      />
    </div>
  );
};
