import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import log from "loglevel";
import { URLS } from "../UrlConst";
import { Task } from "./task";
import { AxiosRequestConfig } from "axios";
import { MondalInputB4Post, MondalInputB4Put, MondalInputPost, MondalInputPut } from "../components/kanban/TaskModal";
import { createKanbanBoard_task } from "../util/swrCRUD";
import { message } from "antd";
import { format } from "date-fns";

// Main state for columns
export interface ColumnTask {
  [key: number]: Task[];
}

// Pass on columnID from front-end for changing to {1: colmmnTaskArray}
export interface ColumnTaskResponse {
  id: number;
  response: Task[];
}

// This is used in boardList on BoardFull.
// Data on http://127.0.0.1:8018/api/kanbanboard/3 ,
// List on column with lite-data. (just ids for tasks and info of column.)
export interface ColumnLite {
  id: number;
  title: string;
  taskIds: number[];
  owner: number;
}

// This is data from ${URLS.columnTask}/${columnID}/tasks
// http://127.0.0.1:8018/api/kanbanboard/column/5/tasks
// Full tasks for a column

export const _fetchColumnTask = createAsyncThunk(
  "columnTask/_fetch",
  async (columnID: number) => {
    const response = await axios.get(`${URLS.columnTask}/${columnID}/tasks`);
    return { id: columnID, response: response.data };
  }
);

// Very clunky code from prev (here and backend)
// TODO: How to handle fail case? No ".then" can be used???
/* Moving task to different column. Sorts out order on backend */
export const updateTaskToNewColumn = createAsyncThunk(
  "columnTask/updateTaskToNewColumn",
  async (data: {
    type: string;
    taskId: number;
    columnId: number;
    columnStartId: number;
    orderNumber: number;
  }) => {
    const response = await axios.patch(`${URLS.task}/${data.taskId}`, data);
    return response.data;
  }
);

/* Moving task within same column. Sorts out order on backend */
export const updateTaskToSameColumn = createAsyncThunk(
  "columnTask/updateTaskToSameColumn",
  async (data: {
    type: string;
    taskId: number;
    columnId: number;
    orderNumber: number;
  }) => {
    log.info('data sending - updateTaskToSameColumn')
    log.debug(data)
    const response = await axios.patch(`${URLS.task}/${data.taskId}`, data);
    return response.data;
  }
);

// Same in SWR, createKanbanBoard_task with notes.
export const createColumnTask_Task = createAsyncThunk(
  "columnTask/createColumnTask_Task",
  async (data: {
    columnID: number, data: MondalInputB4Post
  }) => {
    log.debug(data);
    const { columnID, data: dataPre } = data;
    const _data: MondalInputPost = {
      ...dataPre,
      name: dataPre.task
    }

    const response = await axios.post(`${URLS.columnTask}/${columnID}/tasks`, _data);
    return { columnID: columnID, response: response.data };
  }
)

export const updateColumnTask_Task = createAsyncThunk(
  "columnTask/updateColumnTask_Task",
  async (data: {
    taskID: number, columnID: number, data: MondalInputB4Put
  }) => {
    log.debug(data);
    const { taskID, columnID, data: dataPre } = data;
    const _data: MondalInputPut = {
      ...dataPre,
      name: dataPre.task,
      date_start: format(dataPre.date_start, 'yyyy-MM-dd')
    }

    const response = await axios.put(`${URLS.taskUpdate}/${taskID}`, _data)
    return { columnID: columnID, taskID: taskID, response: response.data };
  }
)

const initialState: ColumnTask = {};

export const columnTaskSlice = createSlice({
  name: "columnTask",
  initialState: initialState,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      return {};
    },
    // How to reorder within same column
    // Hmmm, seems to not reorder.
    reorder: (
      state,
      action: PayloadAction<{
        columnId: number;
        startIndex: number;
        endIndex: number;
      }>
    ) => {
      // Move to better place, and refactor reorder function
      const { columnId, startIndex, endIndex } = action.payload;
      // TODO: Check tasks orders are consecutive after.
      const reorderF: any = (
        list: Iterable<unknown> | ArrayLike<unknown>,
        startIndex: number,
        endIndex: number
      ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };
      state[columnId] = reorderF(state[columnId], startIndex, endIndex);
    },
    insert: (
      state,
      action: PayloadAction<{
        columnId: number;
        task: Task;
        insertIndex: number;
      }>
    ) => {
      const { columnId, task, insertIndex } = action.payload;
      state[columnId].splice(insertIndex, 0, task);
    },
    remove: (
      state,
      action: PayloadAction<{
        columnId: number;
        taskId: number;
      }>
    ) => {
      // Might need to reorder whole column to make sure it goes with correct index
      const { columnId, taskId } = action.payload;
      state[columnId] = state[columnId].filter((task) => {
        log.debug(`Removing ${task.name}`);
        return taskId !== task.id;
      });
    },
  },
  extraReducers: {
    [_fetchColumnTask.fulfilled.type]: (
      state,
      action: PayloadAction<ColumnTaskResponse>
    ) => {
      const { id, response } = action.payload;
      log.info(`${action.type} - ${URLS.columnTask}/${id}/tasks`);
      log.debug(action.payload);

      // const tasksWithColumnId = response.map(task => ({ ...task, columnId: id}))
      // const sortedTasks = tasksWithColumnId.sort((a, b) => a.task_order - b.task_order)
      
      const _tasksWithColumnId = tasksWithColumnId(response, id)
      const _sortedTask = sortTasksWithColumnId(_tasksWithColumnId)

      state[id] = _sortedTask; // Task[]
    },
    [_fetchColumnTask.pending.type]: (_state, action) => {
      log.info(action.type);
    },

    // Used by modal to Add task
    [createColumnTask_Task.fulfilled.type]: (state, action: PayloadAction<{
      columnID: number;
      response: Task;
    }>) => {
      const { columnID, response } = action.payload;
      log.info(`${action.type} - ${URLS.columnTask}/${columnID}/tasks`);
      log.debug(action.payload);

      // Backend should be providing columnId and order, but current endpoint is bit janky and need more work.
      // Passing on previous initial task.columnId information, carried through front-end modal, passed here.
      // WARNING: Possible backend possibily not having order (or here) may cause odd ordering bug
      // But also it's possible modal showing correct data + ordering may work without above odd bugs.
      // Remove this once backend is sorted.
      state[columnID].push({ ...response, columnId: columnID});
    },
    [createColumnTask_Task.pending.type]: (_state, action) => {
      log.info(action.type);
    },
    [createColumnTask_Task.rejected.type]: (_state, action) => {
      log.info(action.type);
      const errorMsg = `Error in ${action.type}`
      message.error(errorMsg);
      log.warn(errorMsg);
      log.info(action);
      log.info(action.response);
    },

    // Used by modal to modify (update, put) task
    [updateColumnTask_Task.fulfilled.type]: (state, action: PayloadAction<{
      taskID: number;
      columnID: number;
      response: Task;
    }>) => {
      const { taskID, columnID, response } = action.payload;
      log.info(`${action.type} - ${URLS.taskUpdate}/${taskID}`);
      log.debug(action.payload);

      // Example of what not to do. Causes fake reordering in front-end display only.
      // Remove (filter) old task. Add at the end.
      // state[columnID] = state[columnID].filter(task => task.id !== taskID)
      // state[columnID].push(response)

      // Backend should be providing columnId and order, but current endpoint is bit janky and need more work.
      // Passing on previous initial task.columnId information, carried through front-end modal, passed here.
      // WARNING: Possible backend possibily not having order (or here) may cause odd ordering bug
      // But also it's possible modal showing correct data + ordering may work without above odd bugs.
      // Remove this once backend is sorted.
      const responsePatched = { ...response, columnId: columnID}
      // Replace taskID with latest from server success response
      state[columnID] = state[columnID].map(task => task.id === taskID ? responsePatched : task)
    },
    [updateColumnTask_Task.pending.type]: (_state, action) => {
      log.info(action.type);
    },
    [updateColumnTask_Task.rejected.type]: (_state, action) => {
      log.info(action.type);
      const errorMsg = `Error in ${action.type}`
      message.error(errorMsg);
      log.warn(errorMsg);
      log.info(action);
      log.info(action.response);
    },
  },
});


// Add columnId to task. Extract for to make more test friendly
export const tasksWithColumnId = (response: Task[], columnId: number) => response.map(task => ({ ...task, columnId }))

// Sort Task[] based off task_order field.
export const sortTasksWithColumnId = (tasks: Task[]) => tasks.sort((a, b) => a.task_order - b.task_order)



// Get all needed columns, (prefetch?)

// each column need it's own 'loading'?

// [
//   1: {id: 1, name: "Todo"},
//   2: {id: 2, name: "Doing"},
//   3: {id: 3, name: "Done"}
// ]

// Maybe this is easiest? No need to process for loading. on get
// Only initial in fetch?
// [
//   1: {data: {id: 1, name: "Todo"}, loading: boolean}},
//   2: {id: 2, name: "Doing"},
//   3: {id: 3, name: "Done"}
// ]
