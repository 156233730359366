import React, { Dispatch, useEffect, useReducer } from "react";
import { Statistic, AutoComplete, Button } from "antd";
import { addMinutes, addSeconds } from "date-fns";

import DatePicker from "../DatePicker";
import { namesAndIds_ToAutoCompleteOptions } from "../../select/selectSWR";
import { Task } from "../../store/task";
import { emptyRecord, IPomoRecords } from "./FooterContainer";
import { removeById } from "../../util/utils";
import { useDispatch } from "react-redux";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
// import { appGlobalUserSlice } from "../../store/appGlobalUser";

const { Countdown } = Statistic;
interface Props {
  tasks: Task[];
  // tasksStrings: string[]; // All tasks names for the current kanban board Same order as Ids
  // tasksIds: number[]; // All tasks id for current kanban board. Same order as Strings

  autoStart?: boolean;
  curTaskId: number;
  size?: "small" | "middle"; // Size for componts. Matching antd naming convention.
  disableStartButton?: boolean;

  latestPomoTimerId: (_records: IPomoRecords) => number;
  records: any;
  setRecords: Dispatch<any>;
}

enum ActionType {
  INIT = "INIT",
  START = "START",
  STOP = "STOP",
  FINISH = "FINISH",
}

interface IReducer {
  type: ActionType;
  // end?: Date;
  done?: boolean;
  stopped?: boolean; // User stopped with button. This is needed as when Countdown timer is 0 it auto triggers "on change" and "Action.FINISH" gets triggers always. Conditionally stop this.

  timer?: number; // Countdown component use this to track length
  autoStart?: boolean;

  records?: IPomoRecords;
  setRecords?: Dispatch<any>;
  curTaskId?: number;
}

interface ITimer {
  text: string;
  // start?: Date;
  // end?: Date;
  done: boolean;
  stopped: boolean;

  timer?: number;
  autoStart?: boolean;

  records?: IPomoRecords;
  setRecords?: Dispatch<any>;
  curTaskId?: number;
}

const notifyPermissionAsk = () => {
  // We need to ask the user for permission
  // New change means this has to be when user clicks.
  if (Notification.permission !== "denied") {
    Notification.requestPermission();
  }
};

const notify = () => {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support system notifications");
  }
  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification("Pomodoro Done!");
  }
};

const initialState: ITimer = {
  text: "START",
  done: false,
  stopped: false,
  timer: Date.now(),
  // timer: -100,
};

const POMO_LENGTH = 25;  // Pulled out for easier debugging

const reducer: React.Reducer<ITimer, IReducer> = (state, action) => {
  switch (action.type) {
    case ActionType.INIT:
      // Run in useEffect once at start to add in records and setRecords to be used in reducer here.
      return {
        ...state,
        // records - Not being used in reducer. Delete later if app works fine without it 2020-12-12
        // records: action.records,
        setRecords: action.setRecords,
        ...initialState,
      };
    case ActionType.START:
      document.title="KanXDoro - Running";
      return {
        ...state,
        timer: addMinutes(Date.now(), POMO_LENGTH).getTime(),  // Live setting
        // timer: addSeconds(Date.now(), 7).getTime(),  // For quick development
        text: "STOP",
        stopped: false,
      };
    case ActionType.STOP:
      document.title="KanXDoro";
      return {
        ...state,
        timer: addMinutes(Date.now(), 0).getTime(),
        text: "START",
        stopped: true,
      };

    case ActionType.FINISH:
      document.title="KanXDoro";
      // console.log("FINISHED reducer");
      notify();
      return {
        ...state,
        // timer: Date.now(),
        text: "DONE",
        done: true,
      };
    default:
      throw new Error();
  }
};

export const PomoTimer: React.FC<Props> = ({
  tasks,
  curTaskId,
  autoStart,
  size,
  disableStartButton,
  latestPomoTimerId,
  records,
  setRecords,
}) => {
  const [state, dispatch] = useReducer<React.Reducer<ITimer, IReducer>>(
    reducer,
    initialState
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    // This handles default case and fixes 'X' button causing item to keep counting down
    if (!records[curTaskId].autoStart) {
      dispatch({
        type: ActionType.STOP,
      })
    } else {
      // This allows continuation of countdown after task finishes.
      dispatch({
        type: ActionType.INIT,
        // records: records,
        setRecords: setRecords,
      });

      if (records[curTaskId].autoStart) {
        // Trigger timer to start
        dispatch({
          type: ActionType.START,
        });
        // Disable autoStart so on list, they stop counting.
        records[curTaskId].autoStart = false;
      }
    }
  }, [curTaskId, setRecords]);

  // Dev menu - conditional
  const renderDevMenu = () => {
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.REACT_APP_ENV_DEBUG_BARS === "true" &&
      process.env.REACT_APP_ENV_DEBUG_BAR_FOOTER === "true"
    ) {
      return (
        <div className="bg-gray-400">
          <div className="font-bold italic">Dev Menu (PomoTimer)</div>
          <div>id: {curTaskId} - {JSON.stringify(state)}</div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row items-center">
      <div className="mr-2">
        <AutoComplete
          size={size}
          style={size === "small" ? { width: 160 } : { width: 180 }}
          options={namesAndIds_ToAutoCompleteOptions(tasks, []) as any}
          placeholder="Tasks"
          filterOption={(inputValue, option) => // @ts-ignore temp ignore
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={(value, option: { value: string, label: string, taskid: number }) => {
            reduxDispatch(appGlobalUserSlice.actions.globalSelectedTaskId(0)) // This effectively acts as lock/unlock for the field. Unlock so it doesn't get auto refreshed.
            setRecords({
              ...records,
              [curTaskId]: {
                ...records[curTaskId],
                task: value,
                taskId: option.taskid, // taskId is added to AutoComplete option structure. When Task can't be found undefined effectively bypassing matching of Footer Tasks and kanban task.
              },
            });
          }}
          onSelect={(_value: string, option: { value: string, label: string, taskid: number }) => {
            console.log(option)
            // onSelect as well as onChange is required to trigger properly selecting and making kanban highlight and footer sync.
            reduxDispatch(appGlobalUserSlice.actions.globalSelectedTaskId(option.taskid))
          }}
          value={records[curTaskId].task}
          className="mr-2"
        />
        <DatePicker
          size={size}
          style={size === "small" ? { width: 160 } : { width: 200 }}
          className="mr-1"
          showTime
          showToday={true}
          format="YY-MM-DD  h:mm a"
          placeholder="Start Time"
          onChange={(val) =>
            setRecords({
              ...records,
              [curTaskId]: { ...records[curTaskId], start: val },
            })
          }
          // value={state.start}
          value={records[curTaskId].start}
          use12Hours
        />
        <DatePicker
          size={size}
          style={size === "small" ? { width: 160 } : { width: 200 }}
          className="mr-1"
          showTime
          showToday={true}
          format="YY-MM-DD  h:mm a"
          placeholder="Finish Time"
          onChange={(val) =>
            setRecords({
              ...records,
              [curTaskId]: { ...records[curTaskId], end: val },
            })
          }
          // value={state.end}
          value={records[curTaskId].end}
          use12Hours
        />
      </div>
      <div className="flex flex-row items-center">
        <Countdown
          // className="text-3xl"
          valueStyle={
            size === "small" ? { fontSize: "1.5rem" } : { fontSize: "2.5rem" }
          }
          format="mm:ss"
          data-testid="countdown-container"
          // value={timeVal.getTime()}
          value={state.timer}
          onFinish={() => {
            // CountDown Component always counts down by itself from start... hence below...
            // onFinish always get triggered when value is 0.
            // Thus Need to conditionally decide when I triggered onFinish or it's automatic through my state.
            // 
            // 
            /*
            onFinish called when I press X from above component as starting value is 0 i guess... so bug :(

            So there is only 1 way to trigger ending. It's getting confused between these states.
            1. Countdown timer reaches 0. this gets called and ActionType.FINISH + 'STOP exists.
            2. X is pressed and ActionType.INIT + 'STOP' exists

            UPDATE: Fixed through controller time at initial creation through 'autoStart' on record
            */

            if (!state.stopped) {
              // Freaking hope their version counts down accurately and not based off setInterval as 1 sec.

              // Get PomoTimer to correct state (For component control)
              dispatch({ type: ActionType.FINISH });

              // Update current instance of PomoTimer data time
              // + (This need to be here. If I do setRecord again with ...records it will reset to original not yet refreshed state so first setRecord line will get ignore.)
              // Automatically carry on timer with new PomoTimer instance. Maybe add this as hook type of function
              
              
              // How about X sets something so chain of reaction gets trigged and doesn't delete right away here.2
              setRecords({
                ...records,
                [curTaskId]: { ...records[curTaskId], end: new Date() },
                ...emptyRecord({
                  ...records[curTaskId],
                  autoStart: true,
                  start: new Date(),
                  end: undefined,
                }),
              });
            }


          }}
        />

        <div className={`${size === "small" ? "text-xl" : "text-3xl"} mx-2`}>
          Session
        </div>

        {!disableStartButton && <Button
          className={`mx-2`}
          // danger={state.done && true}
          type={state.done ? "dashed" : "default"}
          shape="round"
          onClick={() => {
            if (state.text === "STOP") {
              setRecords({
                ...records,
                [curTaskId]: { ...records[curTaskId], start: '' },
              })
              dispatch({
                type: ActionType.STOP,
              });
            }
            if (state.text === "START") {
              notifyPermissionAsk(); // Due to change, permission ask has to be inside of click.
              // setTimeVal(addSeconds(Date.now(), 5));

              // if (records && curTaskId) {
              const timerStart = records[curTaskId].start;

              if (timerStart) {
                dispatch({
                  type: ActionType.START,
                });
              }

              // If user hasn't specified time auto add now
              if (!timerStart) {
                setRecords({
                  ...records,
                  [curTaskId]: { ...records[curTaskId], start: new Date() },
                });
                dispatch({
                  type: ActionType.START,
                });
              }
            }
            if (state.text === "DONE") {
              setRecords(removeById(records, curTaskId));
              dispatch({
                type: ActionType.INIT,
              });
            }
          }}
        >
          {state.text}
        </Button>}

        {/* +25m button */}
        <Button
          className={`mx-0`}
          type={state.done ? "dashed" : "default"}
          shape="round"
          disabled={!records[curTaskId].start && true}
          // disabled={records[curTaskId].start ? false : true}
          onClick={() => {
            if(records[curTaskId].start) {
              setRecords({
                ...records,
                [curTaskId]: { ...records[curTaskId], end: addMinutes(records[curTaskId].start, POMO_LENGTH) },
              });
            }
          }}
        >
          +{POMO_LENGTH}m
        </Button>
      </div>
      {renderDevMenu()}
    </div>
  );
};
