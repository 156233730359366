import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import log from "loglevel";
import { UsefulDates } from "../util/dateUtils";

// for User changed state to be shared within app (changes tab and come back, settings still same)

const usefulDates = new UsefulDates();

// TODO: This doesn't seem to be used anywhere.
// I wanna use with initalState but there's conflict and Im 99% sure it won't break, and I need to modify some of types in here.
// It will fix kanbanProjectFilter issue below.
export interface AppGlobalUser {
  // PomoQueryTable
  pomoQueryTableStartDate: Date;
  pomoQueryTableEndDate: Date;

  // RecordTable
  recordTableStartDate: Date;
  recordTableEndDate: Date;

  // ProjectTable (not yet used)
  projectTableStartDate: Date;
  projectTableEndDate: Date;

  // TaskTable (not yet used)
  taskTableStartDate: Date;
  taskTableEndDate: Date;

  // Kanbanboard
  hideArchived: boolean;
  kanbanProjectFilter: string[];

  // Task Modal Visible
  modalVisible: boolean;
  modalTitle: string;
  // modalColumnId: number | undefined;
  // modalTaskId: number | undefined;
  modalColumnId: number;
  modalTaskId: number;

  // Footer Pomodoro
  globalSelectedTaskId?: number;
}

export const initialState = {
  // PomoQueryTable
  pomoQueryTableStartDate: usefulDates.today.toISOString(),
  pomoQueryTableEndDate: usefulDates.tomorrow.toISOString(),

  // RecordTable
  recordTableStartDate: usefulDates.today.toISOString(),
  recordTableEndDate: usefulDates.tomorrow.toISOString(),

  // ProjectTable
  projectTableStartDate: usefulDates.today.toISOString(),
  projectTableEndDate: usefulDates.tomorrow.toISOString(),

  // TaskTable
  taskTableStartDate: usefulDates.today.toISOString(),
  taskTableEndDate: usefulDates.tomorrow.toISOString(),

  // Kanbanboard
  hideArchived: true,
  kanbanProjectFilter: [],

  // Task Modal Visible
  modalVisible: false,
  modalTitle: '',
  modalColumnId: 0,
  modalTaskId: 0,

  // Footer Pomodoro
  globalSelectedTaskId: 0, // using 0 as "undefined. Task not set."
};

export const appGlobalUserSlice = createSlice({
  name: "global-user",
  initialState: initialState,
  reducers: {
    // PomoQueryTable
    pomoQueryTableStartDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["pomoQueryTableStartDate"] = action.payload;
    },
    pomoQueryTableEndDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["pomoQueryTableEndDate"] = action.payload;
    },

    // RecordTable
    recordTableStartDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["recordTableStartDate"] = action.payload;
    },
    recordTableEndDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["recordTableEndDate"] = action.payload;
    },

    // ProjectTable
    projectTableStartDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["projectTableStartDate"] = action.payload;
    },
    projectTableEndDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["projectTableEndDate"] = action.payload;
    },

    // TaskTable
    taskTableStartDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["taskTableStartDate"] = action.payload;
    },
    taskTableEndDate: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["taskTableEndDate"] = action.payload;
    },

    // Kanbanboard
    hideArchived: (state, action: PayloadAction<boolean>) => {
      log.info(`${action.type}`);
      state["hideArchived"] = action.payload;
    },
    kanbanProjectFilter: (state, action: PayloadAction<string[]>) => {
      log.info(`${action.type}`);
      state["kanbanProjectFilter"] = action.payload;
    },

    // Task Modal
    modalVisible: (state, action: PayloadAction<boolean>) => {
      log.info(`${action.type}`);
      state["modalVisible"] = action.payload;
    },
    modalTitle: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      state["modalTitle"] = action.payload;
    },
    modalColumnId: (state, action: PayloadAction<(number|undefined)>) => {
      log.info(`${action.type}`);
      // state["modalColumnId"] = action?.payload ?? 0;
      // state["modalColumnId"] = action?.payload;
      if (action) {
        state["modalColumnId"] = action.payload as any
      }

    },
    modalTaskId: (state, action: PayloadAction<(number|undefined)>) => {
      log.info(`${action.type}`);
      // state["modalTaskId"] = action?.payload;
      state["modalTaskId"] = action?.payload ?? 0;
    },

    // Footer Pomodoro
    globalSelectedTaskId: (state, action: PayloadAction<number>) => {
      log.info(`${action.type}`);
      state["globalSelectedTaskId"] = action.payload;
    },
  },
});
