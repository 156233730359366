import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import log from 'loglevel';
import { URLS } from '../UrlConst';
import { PomoCategories } from './typesShared';
// import { Task } from './task';


// Everything needs to be optional of ? since they may exist or not
export interface TaskModalInterface {
  // title?: string;

  id?: number;
  name?: string;
  date_start?: string;
  timestamp?: string;
  description?: string | null;
  project?: number | null;
  type?: PomoCategories;
  categories?: string[];
  archived?: boolean;
}


const initialState: TaskModalInterface = {};

// export const _fetchCategories = createAsyncThunk(
//   'category/_fetch',
//   async () => {
//     const response = await axios.get(URLS.category);
//     return response.data
//   }
// )

// Future: i think this is not used?? Maybe del?
export const appTaskModalSlice = createSlice({
  name: 'modalTask',
  initialState: initialState,
  reducers: {
    // title: (state, action: PayloadAction<string>) => {
    //   log.info(`${action.type}`);
    //   state['title'] = action.payload;
    // },

    // update: (state, action: PayloadAction<{}>) => {
    //   log.info(`${action.type}`);
    //   // state['title'] = action.payload;
    //   state = state.map(() => {...state, action.payload})
    // },

    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`)
      return {}
    }
  },
  // extraReducers: {
  //   [_fetchCategories.fulfilled.type]: (state, action: PayloadAction<{'categories': string[];}>) => {
  //     log.info(`${action.type} - ${URLS.category}`)
  //     log.debug(action.payload)
  //     state.push(...action.payload.categories);
  //   },
  //   [_fetchCategories.pending.type]: (_state, action) => {
  //     log.info(action.type)
  //   }
  // }
})