import { startOfToday, addDays, subDays, addHours, startOfWeek, startOfMonth } from 'date-fns'


// Useful date ranges
// Default hour for date is between 4am to 4am (e.g 20th 4am to 21st 4am)

// Week Config
const weekStartsOn = 1;  // 0 = Sunday, 1 = Monday.

export class UsefulDates {
  //fields
  today: Date;
  tomorrow: Date;
  yesterday: Date;
  this_week_start: Date;
  this_month_start: Date;

  //constructor
  constructor(today=startOfToday(), offSetHours=4) {
    this.today = addHours(today, offSetHours);

    this.tomorrow = addDays(this.today, 1);
    this.yesterday = subDays(this.today, 1);

    this.this_week_start = addHours(startOfWeek(this.today, {weekStartsOn: weekStartsOn}), offSetHours);
    this.this_month_start = addHours(startOfMonth(this.today), offSetHours);
  }
}

// Other processing useful 

// Helps with rounding which requires .toFix(x) and that converts to string.
export const mean = (total: number, by: number, dp: number = 2): number => {
  const mean = total / by;    // Long decimal possible. Below to fix it and round to 3 decimal place.
    // rounds need multiplcation, decision without using other library. This converts to string and back to number but toFixed does what I want.
  const meanFixedDecimalPlace = Number(mean.toFixed(dp))  // requires conversion to string and back to number
  return meanFixedDecimalPlace
}

// Add more as needed, however I found it littered interface and I didn't use outside of today or yesterday.
// I think what I want is something to show trend over weeks which is why I put all these different dates.
// Format date used in RangePicker
export const ranges = (): any => {
  const usefulDates = new UsefulDates();

  const _ranges = {
    Today: [usefulDates.today, usefulDates.tomorrow],
    Yesterday: [usefulDates.yesterday, usefulDates.today],
    'This week so far': [usefulDates.this_week_start, usefulDates.tomorrow],
    'This month so far': [usefulDates.this_month_start, usefulDates.tomorrow],
    // 'This week': [useful_dates.todays_week__start, useful_dates.todays_week__end],
    // 'This Month': [useful_dates.month_1_ago__start, useful_dates.month_1_ago__end],

    // // By weeks period
    // // '1 week period': [useful_dates.week_1_ago__start, useful_dates.week_1_ago__end],
    // 'Period 2w': [useful_dates.week_2_ago__start, useful_dates.week_1_ago__end],
    // 'Period 3w': [useful_dates.week_3_ago__start, useful_dates.week_1_ago__end],
    // 'Period 4w': [useful_dates.week_4_ago__start, useful_dates.week_1_ago__end],
    // 'Period 5w': [useful_dates.week_5_ago__start, useful_dates.week_1_ago__end],
    // 'Period 6w': [useful_dates.week_6_ago__start, useful_dates.week_1_ago__end],
  
    // // By 1 week interval
    // 'Interval 1w': [useful_dates.week_1_ago__start, useful_dates.week_1_ago__end],
    // 'Interval 2w': [useful_dates.week_2_ago__start, useful_dates.week_2_ago__end],
    // 'Interval 3w': [useful_dates.week_3_ago__start, useful_dates.week_3_ago__end],
    // 'Interval 4w': [useful_dates.week_4_ago__start, useful_dates.week_4_ago__end],
    // 'Interval 5w': [useful_dates.week_5_ago__start, useful_dates.week_5_ago__end],
    // 'Interval 6w': [useful_dates.week_6_ago__start, useful_dates.week_6_ago__end],
  
    // // By months period
    // 'Period 1m': [useful_dates.month_1_ago__start, useful_dates.month_1_ago__end],
    // 'Period 2m': [useful_dates.month_2_ago__start, useful_dates.month_1_ago__end],
    // 'Period 3m': [useful_dates.month_3_ago__start, useful_dates.month_1_ago__end],
    // 'Period 6m': [useful_dates.month_6_ago__start, useful_dates.month_1_ago__end],
  
    // // By 1 month interval
    // 'Interval 1m': [useful_dates.month_1_ago__start, useful_dates.month_1_ago__end],
    // 'Interval 2m': [useful_dates.month_2_ago__start, useful_dates.month_2_ago__end],
    // 'Interval 3m': [useful_dates.month_3_ago__start, useful_dates.month_3_ago__end],
  }

  return _ranges;
}