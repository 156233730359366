import React from "react";
import { Spin } from "antd";
import {
  VictoryBar,
  VictoryChart,
  VictoryPolarAxis,
  VictoryTheme,
} from "victory";

import { Life, lifeLabels, LifeAddEmpty } from './lifeSWR';
import log from 'loglevel';


interface Props {
  data: Life | LifeAddEmpty;
}

export const LifePolarChart: React.FC<Props> = ({ data }) => {
  log.debug(data)
  const labels = Object.keys(lifeLabels)

  return (
    <VictoryChart
      polar
      theme={VictoryTheme.material}
      innerRadius={15}
      animate={{ duration: 500, onLoad: { duration: 250 } }}
    >
      {labels.map((key: string, i: number) => {
        log.debug(key)
        // console.log(data[key])
        return (
          <VictoryPolarAxis
            dependentAxis
            key={i}
            label={`${lifeLabels[key]} \n ${data[key]}`}
            labelPlacement="perpendicular"
            style={{
              tickLabels: { fill: "none" },
              // grid: { stroke: "grey", strokeDasharray: "4, 8" }
            }}
            axisValue={i}
            domain={[0, 10]}
          />
        );
      })}
      <VictoryBar
        style={{ data: { fill: "#458ca8", width: 30 } }}
        // key={Array.from(Array(Object.keys(lifeLabels).length).keys())}
        data={labels.map((label, i) => {
          return { x: i, y: data[label] };
        })}
      />
    </VictoryChart>
  );
};
