export const sampleFooterTaskData = {
  "1606808675351": {
    task: "Play",
    taskId: 53,
    start: new Date("2020-12-01T07:44:49.368Z"),
    id: 1606808675351,
    end: new Date("2020-12-01T07:44:59.400Z"),
  },
  "1606808699400": {
    task: "Play",
    taskId: 53,
    start: new Date("2020-12-01T07:44:59.400Z"),
    id: 1606808699400,
    autoStart: false,
    end: new Date("2020-12-01T07:45:09.444Z"),
  },
  "1606808709444": {
    task: "Play",
    taskId: 53,
    start: new Date("2020-12-01T07:45:09.444Z"),
    id: 1606808709444,
    autoStart: false,
    end: new Date("2020-12-01T07:45:19.466Z"),
  },
  "1606808719466": {
    task: "Play",
    taskId: 53,
    start: new Date("2020-12-01T07:45:19.466Z"),
    id: 1606808719466,
    autoStart: false,
    end: new Date("2020-12-01T07:45:29.512Z"),
  },
  "1606808729512": {
    task: "Play",
    taskId: 53,
    start: new Date("2020-12-01T07:45:29.512Z"),
    id: 1606808729512,
    autoStart: false,
    end: new Date("2020-12-01T07:45:39.579Z"),
  },
  "1606808739579": {
    task: "",
    start: new Date("2020-12-01T07:45:39.579Z"),
    id: 1606808739579,
    autoStart: false,
  },
};