import React from "react";
import { MainLayout } from "./common/MainLayout";
import { TaskTable } from './table/TaskTable';

interface Props {}

export const TaskTableContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <TaskTable />
    </MainLayout>
  );
};
