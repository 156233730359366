import { BoardLite } from '../../store/boardList';
import { ColumnTask } from '../../store/column';
import { Task } from '../../store/task';


// take in array of json objects (BoardLite)
// returns ID only


export const getActiveBoards = (boards: BoardLite[]) => {
  // Returns non-archived boards
  const activeBoardsOnly = boards.filter(board => !board.archived);
  return activeBoardsOnly;
}

export const getBoardIDs = (boards: BoardLite[]) => {
  // return array of board IDs.
  const ids = boards.map(board => board.id);
  return ids;
}

export const getActiveBoardsIDs = (boards: BoardLite[]) => {
  // Combien getActiveBoards and getBoardIDs
  // Returns array e.g [1, 2, 4, 5]
  return getBoardIDs(getActiveBoards(boards))
}

export const generateInternalColumnNameIds = (columnIDs: (string|number)[]) => {
  // Generates column name in a format used internally and on backend.
  // Return array e.g ["column-5", "column-6", "column-8"]
  return columnIDs.map(id => `column-${String(id)}`);
}


/* Beautiful-Dnd naming helpers.
Each Draggable or Droppable needs ID.
using Column or TaskID will conflict as column.id=1, task.id=1
Adding pre-fix to manage clash.
Also Beautiful-Dnd passes this information to decide what to do on onDragEnd.
This is only way to rearrange items on the board.
*/

export const columnName_to_ID = (columnName: string) => {
  // Converts column name back to ID
  // Return number, e.g 5
  return Number(columnName.replace('column-', ''));
}


// using snake_case instead of camelCase - way clearer.
export const taskId_to_taskDraggableId = (taskId: number) => {
  // Simply makes task-2 from 2. Creating func to keep logic here.
  return `task-${taskId}`;
};

export const taskDraggableId_to_taskId = (draggableId: string) => {
  // Simply makes task-2 from 2. Creating func to keep logic here.
  return Number(draggableId.replace('task-', ''))
};


/* */

export const getTask_in_columnTask_byId = (columnTask: Task[], taskId: number) => {
  return columnTask.filter(task => task.id === taskId)[0]
}