import { IPomoRecords } from "../components/timer/FooterContainer";


export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const removeById = (_records: IPomoRecords, id:number) => {
  // Takes object, id and removes the key
  // For pomo usage, takes records, id and removes the id
  const { [id]: removed, ...rest } = _records
  return rest
}