import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';

/*
Newer import has issue with typescript jest testing. Using
"If the above steps do not work correctly, you can refer to antd4-generate-picker/antd-ts."
(github source code) as documented
*/

// import generatePicker from 'antd/es/date-picker/generatePicker';
// import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export default DatePicker;