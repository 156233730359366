import useSWR from 'swr';
import { URLS } from '../../UrlConst';
import { urlPlusPagination, fetcher, fetcherFull } from '../../util/swrHelper';
import log from 'loglevel';
import { AxiosRequestConfig } from "axios";

// "index": 0,
// "id": 11,
// "columnNameId": "column-11"

export interface Order {
  index: number;
  id: number;
  columnNameId: string;
  columnName: string;
}

export const useOrder = (
  boardId: string, // come in from of id, e.g 1
  config?: AxiosRequestConfig,
): { data: Order[]; isLoading: boolean; isError: boolean; isValidating: boolean; mutate: any; } => {

  const url = `${URLS.order}/${boardId}/order`;
  log.debug(url);

  const { data, error, isValidating, mutate } = useSWR([url], fetcher);
  // console.log(`isValidating: ${isValidating}`)
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
    mutate: mutate,
  };
};
