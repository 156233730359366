import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { _authLogin, authSlice } from "../../store/appAuth";
import { RootState } from "../../store/store";
import { MainLayout } from "../common/MainLayout";
import { CenteredVerHor } from "../style/CenteredVerHor";

interface Props {}

// Because it's logged out state, react router should take user to login page.
export const LogoutContainer: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  return (
    <MainLayout>
      <CenteredVerHor>
        Logout by, Clears state - (authSlice.actions.nuke)
        <Button
          disabled={auth.token ? false : true}
          onClick={() => {
            dispatch(authSlice.actions.nuke());
          }}
        >
          Logout
        </Button>
      </CenteredVerHor>
    </MainLayout>
  );
};
