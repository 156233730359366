
import useSWR from 'swr';
import { URLS } from '../../UrlConst';
import { DjangoPagination } from '../../store/typesShared';
import { urlPlusPagination, fetcher } from '../../util/swrHelper';
import log from 'loglevel';

export interface BaseMood {
  [key: string]: any;

  mood: number;
  comment: string;
  categories: string[];
  feelings: string[];
}


export interface MoodAddEmpty extends BaseMood {
  date: Date | null;
};

export interface Mood extends BaseMood {
  date: string;
  id?: number;
  timestamp?: string;
  owner?: number;
};

export interface MoodResponse extends DjangoPagination {
  results: Mood[];
}

export const useMood = (paginationIndex?: number | null, paginationUrl?: string): {data: MoodResponse, isLoading: boolean, isError: boolean} => {

  const url = urlPlusPagination(URLS.mood, paginationIndex, paginationUrl);
  log.debug(url);

  const { data, error } = useSWR(url, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

export const useGlobalFeelings = (): {data: string[], isLoading: boolean, isError: boolean} => {

  log.debug(URLS.feeling);
  const { data, error } = useSWR(URLS.feeling, fetcher);
  log.debug("useGlobalFeelings data");
  log.debug(data);
  return {
    data: data? data.feelings : [],
    isLoading: !error && !data,
    isError: error
  }
}
export const useGlobalMoodCategories = (): {data: string[], isLoading: boolean, isError: boolean} => {

  log.debug(URLS.moodCategory);
  const { data, error } = useSWR(URLS.moodCategory, fetcher);
  log.debug("useGlobalMoodCategories data");
  log.debug(data);
  return {
    data: data? data.categories : [],
    isLoading: !error && !data,
    isError: error
  }
}
