import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
  DropResult,
  ResponderProvided,
  DroppableProvided,
} from "react-beautiful-dnd";
import { Column } from "./Column";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import {
  generateInternalColumnNameIds,
  getActiveBoardsIDs,
  getTask_in_columnTask_byId,
  taskDraggableId_to_taskId,
} from "./utils";
import { CenteredVerHor } from "../style/CenteredVerHor";
import { Spin, Switch, message, Select } from "antd";
import { arrloadingChecker } from "../../select/appLoading";
import { BoardFull } from "../../store/boardList";
import log from "loglevel";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
import { useOrder } from "./OrderSWR";
import { updateColumnOrder } from "../../util/swrCRUD";
import { useProject, Project } from "../table/projectSWR";
import {
  createProjectsList,
  listToAutoCompleteOptions,
} from "../../select/selectSWR";
import { URLS } from "../../UrlConst";
import {
  columnTaskSlice,
  updateTaskToNewColumn,
  updateTaskToSameColumn,
} from "../../store/column";
import { TaskModal } from "./TaskModal";
import { KanbanMenuBar } from "./KanbanMenuBar";


interface Props {
  id?: string;
}

const reorder: any = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const KanbanBoard: React.FC<Props> = () => {
  const dispatch = useDispatch();

  // Create list of project for project filtering
  // TODO: handle Error case
  const {
    data: projectData,
    isLoading: projectDataIsLoading,
    isError: projectDataIsError
  } = useProject();
  const projects = projectData?.results;
  const projectsList = projectDataIsLoading ? [] : createProjectsList(projects);


  const appLoading = useSelector((state: RootState) => state.appLoading);
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);

  const { id: boardId } = useParams();

  // Prob improved version of keeping in obj with id as key
  const boardListFull = useSelector((state: RootState) => state.boardListFull);
  const curKanbanBoard = boardListFull[Number(boardId)] as BoardFull;

  const columnTask = useSelector((state: RootState) => state.columnTask);

  // For order of columns
  const {
    data: columnOrders,
    isLoading: columnOrdersIsLoading,
    isError: columnOrdersIsError,
    isValidating: columnOrdersIsValidating,
    mutate,
  } = useOrder(boardId);

  const onDragEndColumn = (result: DropResult) => {
    /*
    Uses SWR state management system.

    Use front-end calculated value to be responsive immediately.
    Backend will do similar reordering calculation but waiting and changing makes front-end look jurky or
    Flashing loader which can hardly be seens sometimes.
    This mutate is pre-baked with key returned from useOrder

    */
    const { draggableId, source, destination } = result;

    // For typescript error need to keep two checks in here or destination could be null so it complains

    // dropped outside the list
    if (!destination) {
      return;
    }

    // List dropped at same location.
    if (destination.index === source.index) {
      return;
    }

    // Create new order on front-end for responsiveness
    const newColumnOrders = reorder(
      columnOrders,
      source.index,
      destination.index
    );

    mutate(newColumnOrders, false); // Use front-end calculated value to be responsive immediately.

    log.debug("columnOrders");
    log.debug(columnOrders);
    log.debug("newColumnOrders");
    log.debug(newColumnOrders);

    const newOrderData = {
      columnId: draggableId,
      oldOrder: source.index,
      newOrder: destination.index,
    };
    log.debug("newOrderData");
    log.debug(newOrderData);

    // Send new order change to Backend.
    // log.debug(`${URLS.order}/${boardId}/order`)
    updateColumnOrder(boardId, { data: newOrderData })
      .then((res) => {
        console.log(res);
        // Get SWR to re-fetch / revalidate
        console.log("patched moving column. running mutate");
        mutate(newColumnOrders, true); // Revalidate backend to sync (Just incase)
      })
      .catch((err) => {
        message.error(`Error - Issue reordering column. Please refresh.`);
        log.warn(`Error - Issue reordering column. Please refresh.`);
        log.info(err);
        log.info(err.response);
      });
  };

  const onDragEndTask = (result: DropResult) => {
    /*
    There can be
    2 cases.
    Case A: Task moves within same column.
    Case B: Task moves to different column.

    Uses Redux state management system.
    */
    const { draggableId, source, destination } = result;

    if (!destination) {
      return;
    }

    const columnId_destination = Number(destination.droppableId);
    const columnId_source = Number(source.droppableId);
    const taskId = taskDraggableId_to_taskId(draggableId);

    // List dropped at same location. (and same column)
    if (
      destination.index === source.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }

    // Case A. Task moving within same column
    if (source.droppableId === destination.droppableId) {
      /* Reorder (within that column) */
      // console.log(columnTask[columnId]);

      // Create new order on front-end for responsiveness
      dispatch(
        columnTaskSlice.actions.reorder({
          columnId: columnId_destination,
          startIndex: source.index,
          endIndex: destination.index,
        })
      );

      // Send info to backend to make same changes.
      dispatch(
        updateTaskToSameColumn({
          type: "UPDATE_TASK_COLUMN_ORDER_SUCCESS",
          taskId: taskId,
          columnId: columnId_destination,
          orderNumber: destination.index,
        })
      );
    }

    // Task moving to different column
    if (source.droppableId !== destination.droppableId) {
      /* Move to different column. aka remove from source column, insert into destination column */

      // Reorder the tasks
      // (Don't need. It's array. Task[]. Not [key: number]: Task[])

      //
      // Create new order on front-end for responsiveness
      //

      // Keep it so we can slice it in
      const task = getTask_in_columnTask_byId(
        columnTask[columnId_source],
        taskId
      );

      // Delete task on source column
      dispatch(
        columnTaskSlice.actions.remove({
          columnId: columnId_source,
          taskId: taskId,
        })
      );

      // Insert task at the correct position on destination column
      dispatch(
        columnTaskSlice.actions.insert({
          columnId: columnId_destination,
          task: task,
          insertIndex: destination.index,
        })
      );

      // Update on backend, update task moving to different column
      dispatch(
        updateTaskToNewColumn({
          type: "UPDATE_TASK_COLUMN_SUCCESS",
          taskId: taskId,
          columnId: columnId_destination,
          columnStartId: columnId_source,
          orderNumber: destination.index,
        })
      );
    }
  };

  const onDragEnd = (result: DropResult) => {
    /*
    droppableId is same as columnId
    */

    const { type } = result;

    if (type === "column") {
      // Rearrange Column (e.g swap Todo, Completed)
      onDragEndColumn(result);
    }

    if (type === "task") {
      // Arrange Task on Column. It could be within same column or move between columns
      onDragEndTask(result);
    }
  };

  const renderColumns = () => {
    /*
    Internally format below is used for dragging.
    KanXDoro v1, ["column-4", "column-5", "column-6", "column-10"]
    KanXDoro v2 (this app), [4, 5, 6, 10]

    <Column> note.
    Tried columnId index. This is not ok.
    Column index requires consecutive number or there might be error. Change to "index={column.id}" to see the warning
    Detected non-consecutive <Draggable /> indexes.(This can cause unexpected bugs)1, 2, 3, [🔥9]

    */


    return columnOrders.map(({ index, id, columnNameId }) => (
      <Column
        // columnNameId={columnNameId}
        columnNameId={`${id}`}
        columnId={id}
        key={id}
        index={index}
      />
    ));
  };

  // Dev menu - conditional
  const renderDevMenu = () => {
    if (process.env.NODE_ENV !== "production" && (process.env.REACT_APP_ENV_DEBUG_BARS === "true") && curKanbanBoard !== undefined) {
      return (
        <div className="bg-gray-400">
          <div>KanbanBoard id: {boardId}</div>
          <div>Board id: {curKanbanBoard.id}</div>
          <div>Board name: {curKanbanBoard.name}</div>
        </div>
      );
    }
  };

  // Loader //
  const allBoardsLoaded = arrloadingChecker(
    Object.values(appLoading.boardListFull)
  );
  log.debug(`allBoardsLoaded: ${allBoardsLoaded}`); // Checks to see boardListFull loaded, which has column information

  // curKanbanBoard is undefined if you go directly to kanban/3, as loading.boardListFull will be {}
  if (allBoardsLoaded || curKanbanBoard === undefined) {
    return (
      <CenteredVerHor>
        <Spin size="large" />
        <div>Loading KanbanBoard</div>
      </CenteredVerHor>
    );
  }

  if (columnOrdersIsLoading) {
    return (
      <CenteredVerHor>
        <Spin size="large" />
        <div>Loading order...</div>
      </CenteredVerHor>
    );
  }

  // Actually I want to validate silently as I now to calculation on front-end. Backend should give same result.
  // if (columnOrdersIsValidating) return (
  //   <CenteredVerHor>
  //     <Spin size='large'/>
  //     <div>ReValidating order...</div>
  //   </CenteredVerHor>
  // )

  // TODO: Improve this
  // No data (first time visit of kanbanboard) it will crash from trying to .map.
  // if data (state in redux) it can keep the page. In that case show "out of sync" icon on top right?
  // if (columnOrdersIsError)
  //   return (
  //     <CenteredVerHor>
  //       {/* Sadly doesn't actually freeze spinning but disapears */}
  //       <Spin size="large" spinning={false} />
  //       <div>Whaaat? There is an error in loading order.</div>
  //     </CenteredVerHor>
  //   );

  return (
    <div className="flex flex-col">
      {renderDevMenu()}
      <KanbanMenuBar />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided: DroppableProvided) => (
            <div
              className="
                flex flex-row pb-6
                border-solid border border-gray-400"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {renderColumns()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <TaskModal />
    </div>
  );
};

/*  Column looks like this
<Column />

Two columns below.

<Draggable draggableId={"all-columns-15"} index={15}>
  {(provided) => (
  <div
    className="w-48 mb-3 p-3 bg-gray-400
      border-solid border border-gray-600
      "
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    Column 1
  </div>
  )}
</Draggable>

<Draggable draggableId={"all-columns-16"} index={16}>
  {(provided) => (
  <div
    className="w-48 mb-3 p-3 bg-gray-400
      border-solid border border-gray-600
      "
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    Column 2
  </div>
  )}
</Draggable>

*/
