import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MainLayout } from "./common/MainLayout";
import { Button } from "antd";
import { CenteredVerHor } from "./style/CenteredVerHor";

interface HomeContainerProps {}

export const HomeContainer: React.FC<HomeContainerProps> = () => {
  const renderDevInfo = () => {
    if (process.env.NODE_ENV !== "production") {
      // console.log(process.env);
      return (
        <div className="flex flex-row mt-5">
          <pre>{JSON.stringify(process.env, null, 2)}</pre>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <CenteredVerHor>
          <h1>Welcome {/* this.props.auth.user.username */} to KanXDoro</h1>
          <div>Personal app to take you to greater heights : )</div>
          <div className="mt-5">
            <Button>
              <Link to="/dash">Start</Link>
            </Button>
          </div>
          {renderDevInfo()}
        </CenteredVerHor>
      </MainLayout>
    </Fragment>
  );
};
