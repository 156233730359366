import React, { useState } from "react";
import { Task } from "../../store/task";
import { Button, Modal } from "antd";
import { TaskModal } from "./TaskModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { appGlobalUserSlice } from "../../store/appGlobalUser";

interface Props {
  taskId?: number;
  columnId: number|undefined;
}

/*
If there is taskId, show 'modify modal'
If there isn't show 'add modal'
*/

export const TaskModalAdd: React.FC<Props> = ({ taskId, columnId }) => {
  // const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);

  return (
    <div className={`mb-2`} style={{ marginTop: `auto` }}>
      <Button
        size="small"
        onClick={() => {
          dispatch(appGlobalUserSlice.actions.modalTitle("Add Task"));
          dispatch(appGlobalUserSlice.actions.modalColumnId(columnId));
          dispatch(
            appGlobalUserSlice.actions.modalVisible(!appGlobalUser.modalVisible)
          );
        }}
      >
        +
      </Button>
    </div>
  );
};
