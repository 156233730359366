import React from "react";
import { MainLayout } from "./common/MainLayout";
import { PomoQueryTable } from './table/PomoQueryTable';

interface Props {}

export const PomoQueryContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <PomoQueryTable />
    </MainLayout>
  );
};
