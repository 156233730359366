import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import log from 'loglevel';

import { StrictBoolean } from './typesShared';
import { _fetchTasks } from './task';
import { _fetchBoardList, _fetchBoardFullList } from './boardList';
import { _fetchCategories } from './category';
import { _fetchRecords } from './record';
import { _fetchProjects } from './project';
import { ColumnTaskResponse, updateColumnTask_Task, _fetchColumnTask } from './column';


export interface Loading {
  // [key:string]: StrictBoolean;
  boardList: boolean,
  boardListFull: {[key: number]: boolean},
  columnTask: {[key: number]: boolean},
  task: boolean,
  category: boolean,
  record: boolean,
  project: boolean,
  taskModal: boolean,
}

export const initialState: Loading = {
  boardList: true,
  boardListFull: {},
  columnTask: {},
  task: true,
  category: true,
  record: true,
  project: true,
  taskModal: false,
}


export const appLoadingSlice = createSlice({
  name: 'app-loading',
  initialState: initialState,
  reducers: {
    boardList: (state, action: PayloadAction<StrictBoolean>) => {
      log.info(`${action.type}`);
      state['boardList'] = action.payload;
    },
    boardListFull: (state, action: PayloadAction<{id: number, loading: boolean}>) => {
      log.info(`${action.type}`);
      const { id, loading } = action.payload;
      state['boardListFull'][id] = loading;
    },
    columnTask: (state, action: PayloadAction<{id: number, loading: boolean}>) => {
      log.info(`${action.type}`);
      const { id, loading } = action.payload;
      state['columnTask'][id] = loading;
    },
    task: (state, action: PayloadAction<StrictBoolean>) => {
      log.info(`${action.type}`);
      state['task'] = action.payload;
    },
    category: (state, action: PayloadAction<StrictBoolean>) => {
      log.info(`${action.type}`);
      state['category'] = action.payload;
    },
    record: (state, action: PayloadAction<StrictBoolean>) => {
      log.info(`${action.type}`);
      state['record'] = action.payload;
    },
    project: (state, action: PayloadAction<StrictBoolean>) => {
      log.info(`${action.type}`);
      state['project'] = action.payload;
    }
  },

  // fetchBoardList can be used in extra Reducers as "fetchBoardList"
  extraReducers: {
    // pending
    [_fetchBoardList.pending.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['boardList'] = true;
    },
    // [_fetchBoardFullList.pending.type]: (state, action) => {
    //   log.info(`${action.type} - appLoadingSlice extraReducer`)
    //   console.log(action);
    //   state['boardListFull'] = true;
    // },
    // Cannot add anything to pending.
    // https://github.com/reduxjs/redux-toolkit/issues/496
    //  - "The pending action is dispatched before your payloadCreator is run, so no way to modify that."
    // [_fetchColumnTask.pending.type]: (state, action) => {
    //   log.info(`${action.type} - appLoadingSlice extraReducer`)
    //   console.log(action);
    //   state['columnTask'] = true;
    // },
    [_fetchTasks.pending.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['task'] = true;
    },
    [_fetchCategories.pending.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['category'] = true;
    },
    [_fetchRecords.pending.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['record'] = true;
    },
    [_fetchProjects.pending.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['project'] = true;
    },

    // fulfilled

    [_fetchBoardList.fulfilled.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['boardList'] = false;
    },
    // [_fetchBoardFullList.fulfilled.type]: (state, action) => {
    //   log.info(`${action.type} - appLoadingSlice extraReducer`)
    //   state['boardListFull'] = false;
    // },
    [_fetchTasks.fulfilled.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['task'] = false;
    },
    [_fetchCategories.fulfilled.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['category'] = false;
    },
    [_fetchRecords.fulfilled.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['record'] = false;
    },
    [_fetchProjects.fulfilled.type]: (state, action) => {
      log.info(`${action.type} - appLoadingSlice extraReducer`)
      state['project'] = false;
    },

    // Modal Task
    [updateColumnTask_Task.fulfilled.type]: (state, action) => {
      state['taskModal'] = false;
    },
    [updateColumnTask_Task.pending.type]: (state, action) => {
      state['taskModal'] = true;
    },
    [updateColumnTask_Task.rejected.type]: (state, action) => {
      state['taskModal'] = false;
    },
    //
  }
})
