import axios, { AxiosRequestConfig } from 'axios';
import log from 'loglevel';
import { URLS } from '../UrlConst';
import { Life } from '../components/life/lifeSWR';
import { Mood } from '../components/mood/moodSWR';
import { ModalInput, MondalInputB4Post, MondalInputPost } from '../components/kanban/TaskModal';
import { Row } from 'antd';


export const createLife = async (data: Life) => {
  log.debug(data);
  const response = await axios.post(`${URLS.life}/`, data);
  return response
}

export const createMood = async (data: Mood) => {
  log.debug(data);
  const response = await axios.post(`${URLS.mood}/`, data);
  return response
}

// List of feelings for Mood
export const createFeeling = async (data: object) => {
  log.debug(data);
  const response = await axios.post(`${URLS.feeling}/`, data);
  return response
}

// List of moods for Mood
export const createMoodCategory = async (data: object) => {
  log.debug(data);
  const response = await axios.post(`${URLS.moodCategory}/`, data);
  return response
}


export const createRecord = async (data: object) => {
  log.debug(data);
  const response = await axios.post(`${URLS.record}/`, data)
  return response
}

// Send new order of a column on KanbanBoard
export const updateColumnOrder = async (boardId: string, config: AxiosRequestConfig) => {
  log.debug(config);
  const response = await axios.patch(`${URLS.order}/${boardId}/order`, config);
  return response
}

// TODO: Create new category
// export const createCategory = async (data) => {
//   log.debug(data);
//   const response = await axios.post(`${URLS.}/`, data);
//   return response
// }

// Create new Task for Kanbanboard
export const createKanbanBoard_task = async (columnID: number, data: MondalInputB4Post) => {
  /* Transform data to be used on backend.
  Call task name
    Front-end "task" (Clearer to keep it task. We can have task, project. both could be name)
    Back-end "name" (From task.name ORM model)
    Do transformation before sending to make it clearer. Keep naming consistent on frontend. Same for backend
  */
  log.debug(data);

  const _data: MondalInputPost = {
    ...data,
    name: data.task
  }

  const response = await axios.post(`${URLS.columnTask}/${columnID}/tasks`, _data);
  return response
}
