import React, { useState, Fragment } from "react";
import { Spin, Row, Col, Pagination } from "antd";
import { useLife, Life } from "./lifeSWR";
import { LifePolarChart } from "./LifePolarChart";
import { parseISO, format } from "date-fns";
import { CenteredVerHor } from '../style/CenteredVerHor';

interface Props {}

export const LifeList: React.FC<Props> = () => {
  const [pageIndex, setPageIndex] = useState<number>(1);

  const { data, isLoading, isError } = useLife(pageIndex);

  const count = data?.count;
  const results = data?.results;
  const lives = results;

  const renderLifeList = (lives: Life[]) => {
    const lifeComponents = lives.map((life: Life, index: number) => {
      const date = parseISO(life.date);
      return (
        <div className="flex flex-col items-center" key={index}>
          <div className="font-bold" title={format(date, "hh:mm:ss a")}>
            {format(date, "yyyy-MM-dd - EEEE")}
          </div>
          <LifePolarChart data={life} />
        </div>
      );
    });
    return lifeComponents;
  };

  if (isLoading)
    return (
      <CenteredVerHor>
        <Spin size="large" />
        <div>Loading Life Assessments</div>
      </CenteredVerHor>
    );

  if (isError)
    return (
      <CenteredVerHor>
        <Spin size="large" spinning={false} />
        <div>Whaaat? There is an error</div>
      </CenteredVerHor>
    );

  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {renderLifeList(lives)}
      </div>
      <Row>
        <Pagination
          size="small"
          total={count}
          current={pageIndex}
          defaultPageSize={30} // Currently Manually matching backend pagination. MoodResultsSetPagination, page_size
          pageSizeOptions={["30"]}
          onChange={(pageIndex) => setPageIndex(pageIndex)}
          showQuickJumper
        />
      </Row>
    </Fragment>
  );
};
