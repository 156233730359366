import useSWR from "swr";
import log from "loglevel";
import { AxiosRequestConfig } from "axios";
import { URLS } from "../UrlConst";
import { fetcher } from "../util/swrHelper";
import { DjangoPagination } from "../store/typesShared";
import { CategoryResponse } from "../store/category";


export const useCategory = (
  config?: AxiosRequestConfig,
): { data: CategoryResponse; isLoading: boolean; isError: boolean } => {

  const url = URLS.category;
  log.debug(url);

  log.debug(config);

  const { data, error } = useSWR([url, config], fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};
