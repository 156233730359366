import { Project, useProject } from "../components/table/projectSWR";
import log from "loglevel";
import { Task } from '../store/task';

// const projectsReponse = useProject();

// const projects = projectsReponse.data.results;

export const createProjectsList = (projects: Project[]) => {
  /*
  returns projects as array
  */
  return projects.map((project) => project.name);
};

export const projectIdToName = (projects: Project[], projectId: Number) => {
  /*
  returns projectName from projectID.
  Returns only 1 item as ID is unique
  */

  const projectFiltered = projects.filter(project => project.id === projectId);

  // id is unique on backend so it will always be 1 item returned in array
  const projectName = Array.isArray(projectFiltered) && projectFiltered.length
            ? projectFiltered[0].name : undefined
  return projectName
};

export const projectNameToId = (projects: Project[], projectName: string) => {
  /*
  returns projectID from projectName.
  Returns array.
  */

  const projectFiltered = projects.filter(project => project.name === projectName);

  // id is unique on backend so it will always be 1 item returned in array
  const projectId = Array.isArray(projectFiltered) && projectFiltered.length
            ? projectFiltered[0].id : undefined
            
  return projectId
};

export const listToAutoCompleteOptions = (
  items: string[],
  currentItems?: string[]
) => {
  /*
  Converts array to options used in AntD Autocomplete options (Pre populated list user can select)

  use currentItems to remove already added categories.

  e.g
  options = [
  { value: 'light', label: 'Light' },
  { value: 'bamboo', label: 'Bamboo' },
  ];
  */
  // Remove currently selected items from autolist
  const nonSelectedItem = items.filter(item => !currentItems?.includes(item))
  return nonSelectedItem.map((item) => ({ value: item, label: item }));
};

export const namesAndIds_ToAutoCompleteOptions = (
  tasks: Task[],
  currentItems?: string[],
) => {
  /*
  Similar to above. Takes in extra parameter of array to use Id for choices.
  Does not need removing ???

  currentItems always shows string of item, and no ID information.

  e.g
  options = [
  { value: 'light', label: 'Light' },
  { value: 'bamboo', label: 'Bamboo' },
  ];
  */

  // 'taskid' instead of 'taskId' due to being added on DOM.
  const items = tasks ? tasks.map((task) => ({id: task.id, name: task.name})) : [];
  return items.map((item) => ({ value: item.name, label: item.name, taskid:item.id }));
};
