// This has List of boards which makes it easier to bootstrap part of app.
// Kanban board will have detailed board and tasks within board.

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import log from 'loglevel';
import { URLS } from '../UrlConst';
import { ColumnLite } from './column';

export interface BoardBase {
  id: number;
  name: string;
  description: string;
  archived: boolean,
  owner: number;
}

export interface BoardLite extends BoardBase {
  timestamp: string;
}
export interface BoardFull extends BoardBase {
  columns: ColumnLite[];
}

//TODO: Improve name. This simply uses id: BoardFull, so I can get by ID
export interface BoardFullByID {
  [key: number]: BoardFull;
}

export interface BoardFullResponse {
  id: number;
  response: BoardFull;
}

const initialStateBoardLite: BoardLite[] = [];
// const initialStateBoardFull: BoardFull[] = [];
const initialStateBoardFull: BoardFullByID = {};


/*            */
/* Board Lite */
/*            */

export const _fetchBoardList = createAsyncThunk(
  'boardList/_fetch',
  async () => {
    const response = await axios.get(URLS.boardList);
    return response.data
  }
)

export const boardListSlice = createSlice({
  name: 'boardList',
  initialState: initialStateBoardLite,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      return [];
    }
    // fetch: ,
    // No remove, or update currently (on backend also?)
  },
  extraReducers: {
    [_fetchBoardList.fulfilled.type]: (state, action: PayloadAction<BoardLite[]>) => {
      log.info(`${action.type} - ${URLS.boardList}`)
      log.debug(action.payload)
      state.length = 0; // Hackish way to remove everything in array
      state.push(...action.payload);
    },
    [_fetchBoardList.pending.type]: (_state, action) => {
      log.info(action.type)
    }
  }
})


/*           */
/* BoardFull */
/*           */

// Uses {id1:data1, id2:data2, id3:data3} format instead of [data1, data2, data3]
export const _fetchBoardFullList = createAsyncThunk(
  'boardListFull/_fetch',
  async (boardID: number) => {
    const response = await axios.get(`${URLS.boardListFull}/${boardID}`);
    return {id: boardID, response: response.data};
  }
)

export const boardListFullSlice = createSlice({
  name: 'boardListFull',
  initialState: initialStateBoardFull,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      return {};
    }
    // fetch: ,
    // No remove, or update currently (on backend also?)
  },
  extraReducers: {
    [_fetchBoardFullList.fulfilled.type]: (state, action: PayloadAction<BoardFullResponse>) => {
      const { id, response } = action.payload as any;
      log.info(`${action.type} - ${URLS.boardListFull}/${id}`);
      log.debug(action.payload);

      state[id] = response;
    },
    [_fetchBoardFullList.pending.type]: (_state, action) => {
      log.info(action.type)
    }
  }
})
