import React, { Fragment } from "react";
import { useLocation } from "react-router";
import { MainLayout } from "../common/MainLayout";
import { CenteredVerHor } from "../style/CenteredVerHor";

interface Error404ContainerProps {}

export const Error404Container: React.FC<Error404ContainerProps> = ({}) => {
  let location = useLocation();
  return (
    <Fragment>
      <MainLayout>
        <CenteredVerHor>
          <div>
            <h1>
              No match for <code>{location.pathname}</code>
            </h1>
          </div>
        </CenteredVerHor>
      </MainLayout>
    </Fragment>
  );
};
