import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { LoginContainer } from '../../components/auth/LoginContainer';
import { message } from 'antd';


const PrivateRoute = ({ component: Component, redirectLink, isAuthenticated, path, ...props }) => {

  // Redirect user to home if already logged in
  // Bit confusing but don't know how to improve logic at mo.
  // If user directly goes to /login and has no auth, it will say try going to /login after user/pass authentication and gives below message. I changed message to make it sound more sensible.
  if (path==='/login' && isAuthenticated) {
    message.success('Logged in. Redirecting to home');
    return <Navigate to={'/'} />;
  }

  // If user isn't logged in or has JWT token, send user to login page.
  if (path!=='/login' && !isAuthenticated) {
    return <Route path="/login" element={<LoginContainer />} />;
  }

  // User has auth
  return <Route path={path} element={<Component />} />
}

export default PrivateRoute;