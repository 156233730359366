import React, { DetailedHTMLProps, FormHTMLAttributes, useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Button } from "antd";
import { Link } from "react-router-dom";
import { CenteredVerHor } from "../style/CenteredVerHor";
import { _authLogin } from "../../store/appAuth";
import chrange from "../../../src/assets/img/chrange.jpg";

interface LoginContainerProps {}

// React router should redirect user to Main after loggin in.
export const LoginContainer: React.FC<LoginContainerProps> = ({}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{ username: string; password: string }>({
    username: "",
    password: "",
  });

  const onFormSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    // e.preventDefault();
    dispatch(
      _authLogin({
        username: state.username,
        password: state.password,
      })
    )
  }

  return (
    <div
      className="w-full h-full"
      // style={{ background: "linear-gradient(to left top, #CC6847, #A83C64)" }}
      style={{ backgroundImage: `url(${chrange})` }}
    >
      <div className="flex items-center justify-center h-screen">
        <div
          className="p-12 rounded-lg"
          style={{
            backgroundColor: "rgba(255, 255, 255, .25)",
            backdropFilter: "blur(5px)",
          }}
        >
          <form
            className="text-center"
            onSubmit={onFormSubmit}
          >
            <fieldset>
              <legend>Login</legend>
              <div>
                <Input
                  className="mb-2"
                  placeholder="Username"
                  type="text"
                  id="username"
                  required
                  onChange={(e) =>
                    setState({ ...state, username: e.target.value })
                  }
                />
              </div>
              <div>
                <Input
                  className="mb-2"
                  placeholder="Password"
                  type="password"
                  id="password"
                  data-testid="password"
                  required
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                />
              </div>
                <Button
                  className="mb-2"
                  disabled={!state.username || !state.password}
                  // onClick={() =>
                  //   dispatch(
                  //     _authLogin({
                  //       username: state.username,
                  //       password: state.password,
                  //     })
                  //   )
                  // }
                  onClick={e => onFormSubmit()}
                >
                  Login
                </Button>

              <div>
                Don't have an account?{" "}
                <Link to="/register" style={{ color: "#f8f9fa" }}>
                  Register
                </Link>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};
