import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MainLayout } from "./common/MainLayout";
import { Button } from "antd";
import { CenteredVerHor } from "./style/CenteredVerHor";

interface Props {}

// Used to show debug info in production
export const InfoContainer: React.FC<Props> = () => {
  return (
    <div>
      <CenteredVerHor>
        <h1>Debug</h1>
        <div>
          <pre>{JSON.stringify(process.env, null, 2)}</pre>
        </div>
      </CenteredVerHor>
    </div>
  );
};
