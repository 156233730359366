import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import log from 'loglevel';

import { URLS } from '../UrlConst';
import { PomoCategories, DjangoPagination } from './typesShared';


// *** DEPRECATED IN FAVOUR IS SWR
// *** This one defo incomplete. Need more methods to handle pagination
export interface Task {
  id: number;
  name: string;
  date_start: string;
  timestamp: string;
  description?: string | null;
  project?: number | null;
  task_order: number;
  goal?: string | null; // Can I remove? I don't think I use this
  record_count?: number | null; // Should this be here? Should it return 0 at least?
  record_goal_weekly?: number;
  record_goal_max?: number;
  type: PomoCategories;
  categories: string[];
  owner: number;
  archived: boolean;
  columnId?: number | undefined;
}

export interface TaskResponse extends DjangoPagination {
  results: Task[];
}


const initialState: Task[] = [];

export const _fetchTasks = createAsyncThunk(
  'task/_fetch',
  async () => {
    const response = await axios.get(URLS.task);
    return response.data;
    }
)

export const taskSlice = createSlice({
  name: 'task',
  initialState: initialState,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`)
      return []
    },
    // remove: ,
    // update: ,
  },
  extraReducers: {
    [_fetchTasks.fulfilled.type]: (state, action: PayloadAction<TaskResponse>) => {
      log.info(`${action.type} - ${URLS.task} `)
      log.debug(action.payload)
      const tasks = action.payload.results;
      state.push(...tasks);
    },
    [_fetchTasks.pending.type]: (_state, action) => {
      log.info(action.type)
    }
  }
})

export const { nuke } = taskSlice.actions;
