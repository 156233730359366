import React from "react";
import { Divider } from 'antd';
import { LifeList } from "./life/LifeList";
import { MainLayout } from "./common/MainLayout";
import { LifeAdd } from './life/LifeAdd';

interface Props {}

export const LifeContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <div className="px-3">
        <LifeAdd />
        <Divider />
        <LifeList />
      </div>
    </MainLayout>
  );
};
