import useSWR from "swr";
import { URLS } from "../../UrlConst";
import { DjangoPagination, PomoCategories } from "../../store/typesShared";
import { urlPlusPagination, fetcher } from "../../util/swrHelper";
import log from "loglevel";
import { AxiosRequestConfig } from "axios";
import { useRef } from "react";

// {
//   "id": 472,
//   "pomodoro": 1,
//   "name": "Ansible Windows",
//   "date_start": "2019-12-16",
//   "timestamp": "2019-11-07T09:34:36.887460",
//   "description": "Didn't manage to find good way to get it working.\n\nBut made chocolatey cli for installing all the apps on google keep.",
//   "goal": "0.00",
//   "archived": true,
//   "type": "Pomodoro",
//   "owner": 1,
//   "project": null,
//   "categories": [
//       "Ansible",
//       "DevOps"
//   ]
// },

export interface BasePomoQuery {
  [key: string]: any;

  id: number;
  pomodoro: number;
  name: string;  // task_name in another pull
  date_start: string;
  timestamp: string;
  description: string | null;
  archived: boolean;
  type: PomoCategories;

  project: string | null;  // Changed on backend from ID to Project Name itself
  categories: string[];
}

export interface PomoQuery extends BasePomoQuery {
  owner?: number;
  goal?: string;  // Maybe should be number. "0.00"
}

export interface PomoQueryResponse extends DjangoPagination {
  results: PomoQuery[];
}

export const usePomoQuerySWR = (
  config?: AxiosRequestConfig,
): { data?: PomoQueryResponse; isLoading: boolean; isError: boolean } => {

  const url = URLS.pomoQuery;
  log.debug(url);

  log.debug(config);
  const { data, error } = useSWR([url, config], fetcher);

  // Use this to return old value if undefined
  const val = useRef();
  if (data !== undefined) {
    val.current = data;
  }

  return {
    data: val.current,
    isLoading: !error && !data,
    isError: error,
  };
};
