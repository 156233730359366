import React, { Fragment } from "react";
import styled from "styled-components";

export const GridHorizontalCenter = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface CenteredVerHorProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const CenteredVerHor: React.FC<CenteredVerHorProps> = ({ children, style, className }) => {
  /* Centers Vertically and Horizontally */
  return (
    <Fragment>
      <div className={`h-full flex flex-col justify-center ${className ? className : ''}`} style={style}>
        <GridHorizontalCenter>{children}</GridHorizontalCenter>
      </div>
    </Fragment>
  );
};
