import useSWR from "swr";
import { URLS } from "../../UrlConst";
import { DjangoPagination, PomoCategories } from "../../store/typesShared";
import { urlPlusPagination, fetcher } from "../../util/swrHelper";
import log from "loglevel";
import { AxiosRequestConfig } from "axios";
import { useRef } from "react";

// "id": 66,
// "start_time": "2019-01-14T03:36:28",
// "end_time": "2019-01-14T04:02:39",
// "task": 2,
// "task_name": "KanXDoro Deving",
// "type": "Pomodoro",
// "comment": "",
// "owner": 1

export interface BaseRecord {
  [key: string]: any;

  id: number;
  start_time: string;
  end_time: string;
  task: number;
  task_name: string;
  type: PomoCategories;
  comment: string;
}

export interface Record extends BaseRecord {
  owner?: number;
}

export interface RecordResponse extends DjangoPagination {
  results: Record[];
}

export const useRecordSWR = (
  config?: AxiosRequestConfig,
): { data?: RecordResponse; isLoading: boolean; isError: boolean } => {

  const url = URLS.record;
  log.debug(url);

  log.debug(config);
  const { data, error } = useSWR([url, config], fetcher);

  // Use this to return old value if undefined
  const val = useRef();
  if (data !== undefined) {
    val.current = data;
  }
  console.log(val)
  console.log(val.current)

  return {
    data: val.current,
    isLoading: !error && !data,
    isError: error,
  };
};
