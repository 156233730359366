import React, { useState, useEffect, Fragment } from "react";
import {
  Drawer,
  Button,
  message,
  Spin,
  Input,
  AutoComplete,
  Badge,
} from "antd";

import {
  UpOutlined,
  MinusCircleOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import { addSeconds } from "date-fns";
import { useBoardTask } from "../table/taskSWR";
import { createRecord } from "../../util/swrCRUD";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "../../store/store";
import { removeById } from "../../util/utils";
import { PomoTimerRow } from "./PomoTimerRow";
import log from "loglevel";

import { UploadOutlined } from "@ant-design/icons";
import { namesAndIds_ToAutoCompleteOptions } from "../../select/selectSWR";
import { appGlobalUserSlice } from "../../store/appGlobalUser";
import { sampleFooterTaskData } from "./example_data";

/*


Add new PomodoRecord looks like this. Add new one at end. Example below adds new empty.
  setRecords({ ...records, ...emptyRecord() })

Removing PomodoRecord
  setRecords(removeById(records, curTaskId))

*/

export interface IAllEmptyInput {
  task?: string;
  taskId?: number;
  valid?: boolean;
}

export interface IPomoRecords {
  [key: number]: IPomoItem;
}

export interface IPomoItem {
  id: number;
  task?: string;
  taskId?: number;
  start?: Date;
  end?: Date;
  autoStart?: boolean;
}

const PomoTimerItemEg: IPomoItem = {
  id: 21,
  task: "Hello test task yo!",
  taskId: 3,
  start: new Date(),
  end: addSeconds(Date.now(), 5),
};

// Generic
export const emptyRecord = (conf?: IPomoItem) => {
  // set "autoStart: true" to trigger pomoTimer component auto starting timer (in useEffect).
  if (conf && conf.id) {
    console.warn("id set in emptyRecord through conf argument will not be set");
  }

  const id = Date.now();
  return {
    [id]: {
      // default configs
      task: "",
      taskId: undefined,
      start: undefined,

      autoStart: false,  // Very important. This is how CountDown timer gets controlled.

      // user modified configs
      ...conf,
      id, // At final. Always internally set id to stay consistent by not allowing config to set this.
    },
  };
};

// Share functions pulled out from component

export const latestPomoTimerId = (_records: IPomoRecords) => {
  // Newest pomo component created, not task itself of backend
  const allIds = Object.keys(_records).map(Number);
  const curTaskId = Math.max(...allIds);
  return curTaskId;
};

export const oldestPomoTimerId = (_records: IPomoRecords) => {
  // Oldest pomo component created, not task itself of backend
  const allIds = Object.keys(_records).map(Number);
  const internalTaskId = Math.min(...allIds);
  return internalTaskId;
};

export const disableXButton = (_records: IPomoRecords) => {
  /*
  Disabled X button works by this being calculated everytime to see if X should appear or not
  ensures there is ALWAYS 1.

  I'm not sure if this is good design for efficiency as it needs to be calculated every time
  component changes, in creation.
  */

  const allIds = Object.keys(_records).map(Number);
  return allIds.length <= 1;
};

export const anyRecordValid = (_records: IPomoRecords) => {
  // Is there is at least 1 pomo record it can upload in our records?
  const recordsInArray = Object.values(_records);
  const validRows = recordsInArray.filter(
    (record) => record.start && record.end && record.taskId
  );
  const validStatus = validRows.length === 0 ? false : true;
  return validStatus;
};

export const uploadPomoRecord = (_records: IPomoRecords, curTaskId: number, setRecords: React.Dispatch<React.SetStateAction<IPomoRecords>>) => {
  /*
  Below does this type of code
  ==========================================
    createRecord({
      task: 3,
      start_time: "2020-11-15T20:30:00.157Z",
      end_time: "2020-11-15T20:31:01.157Z",
    })
      .then((res) => {
        console.log("res");
        console.log(res);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
    };
    ==========================================

    curTaskId is ID of 'internal frontend pomodoro record', not Id of a task here... :(


  Always it will set next empty record to take setting from previous.
  */

  const curPomoRecord = _records[curTaskId];
  // console.log(curPomoRecord);
  createRecord({
    task: curPomoRecord.taskId,
    start_time: curPomoRecord.start,
    end_time: curPomoRecord.end,
    // autoStart: false,  //TODO: START HERE. Timer autostart logic causing bug.
  })
    .then((res) => {
      log.debug("res");
      log.debug(res);
      if (res.status === 201) {
        message.success(`Uploaded Task: ${curPomoRecord.task}`);

        // setRecords(removeById(_records, curTaskId)); // This will fail from async loop issue. Have to use fat arrow like below
        setRecords((_records) => removeById(_records, curTaskId));

        // Populate newest pomobar with previous setting like task and starttime (based on previous end time)
        const { task, taskId, end: prev_end } = curPomoRecord;
        setRecords(() =>
          emptyRecord({
            start: prev_end, // start where old task ended
            task: task,
            taskId: taskId,
            id: Date.now(),
          })
        );
      }
    })
    .catch((err) => {
      message.error(
        `Error uploading PomoRecord. Server may be down, please try again later.`
      );
      log.debug("err");
      log.debug(err);
    });
};


export const FooterContainer: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [records, setRecords] = useState<IPomoRecords>(emptyRecord());
  const [allEmptyInput, setAllEmptyInput] = useState<IAllEmptyInput>({});

  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);
  const reduxDispatch = useDispatch();

  const { id: boardId } = useParams();
  const { data, isLoading, isError, isValidating } = useBoardTask(boardId);

  // Drop msg to user if it can't get board. This should never happen as long as
  // backend is working and user's internet works. App will work as previously.

  // TODO: FirstVSLoadedErrorState - Below error keeps popping. It needs first entry state to behave better
  // isError &&
  //   message.error(`Error - Fetching useBoardTask (board tasks or all tasks)`);

  // const count = data?.count;
  const results = data?.results;
  // const tasks = results;
  const tasks = results?.filter(
    (task) => task.archived !== appGlobalUser.hideArchived
  );

  // Note: Unused. Probably can delete here and every part what passed along to PomoTimerRow, PomoTimer.
  // const curColumnTaskToShow = curColumnTask.filter((task: Task) => task.archived!==appGlobalUser.hideArchived)
  // const tasksStrings = tasks ? tasks.map((task) => task.name) : [];
  // const tasksIds = tasks ? tasks.map((task) => task.id) : [];

  const currentRecordsIds = Object.keys(records); // ids to be created in record

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  // Recursively uploads all in order of what you see on UI (order of component created)
  // Because it needs async, trying to have all in here... can't think of way to do it with 2 functions at mo.
  // Maybe if uploadPomodoro (single upload) returns promise it's possible to .then outside of function like below?
  const uploadAllPomoRecordOrdered = (
    _records: IPomoRecords
  ): IPomoRecords | void => {
    const internal_TaskIds = Object.keys(_records);
    const curTaskId = Number(oldestPomoTimerId(_records));

    // Terminate when no valid records and empty one at footer.
    if (!anyRecordValid(_records) && internal_TaskIds.length < 2) {
      return;
    }

    // 2. Not valid, call itself again with next records minus current record
    if (
      !_records[curTaskId].start &&
      !_records[curTaskId].end &&
      !_records[curTaskId].taskId
    ) {
      const { [curTaskId]: curTask, ...restTask } = _records as any;
      return uploadAllPomoRecordOrdered(restTask as IPomoRecords);
    }

    // Only upload if valid (above should check this already)
    if (
      _records[curTaskId].start &&
      _records[curTaskId].end &&
      _records[curTaskId].taskId
    ) {
      // below is - uploadPomoRecord(_records, Number(curTaskId));
      const curPomoRecord = _records[curTaskId];
      createRecord({
        task: curPomoRecord.taskId,
        start_time: curPomoRecord.start,
        end_time: curPomoRecord.end,
      })
        .then((res) => {
          log.debug("res");
          log.debug(res);
          if (res.status === 201) {
            message.success(`Uploaded Task: ${curPomoRecord.task}`);
            // making it "Number(curTaskId)" number to work around type checking issue with ES6 rest operator omit causing issue.
            setRecords((_records) => removeById(_records, curTaskId));

            const { [curTaskId]: curTask, ...restTask } = _records as any;
            return uploadAllPomoRecordOrdered(restTask as IPomoRecords);
          }
        })
        .catch((err) => {
          message.error(
            `Error uploading PomoRecord. Server may be down, please try again later.`
          );
          log.debug("err");
          log.debug(err);
          return;
        });
    }
  };

  useEffect(() => {
    const newestTaskId = latestPomoTimerId(records);
    const newestTask = records[newestTaskId];

    if (newestTask.taskId !== appGlobalUser.globalSelectedTaskId && tasks) {
      const task = tasks.filter(task => task.id === appGlobalUser.globalSelectedTaskId)[0]  // filter returns array.

      // Wait until task exists in tasks
      if (task) {
        setRecords({
          ...records,
          [newestTaskId]: {
            ...records[newestTaskId],
            task: task.name,
            taskId: appGlobalUser.globalSelectedTaskId, // taskId is added to AutoComplete option structure
          }
        })
      }
    }
  }, [appGlobalUser.globalSelectedTaskId, records, tasks, reduxDispatch]);

  const renderAllPomoTimer = (_records: IPomoRecords) => {
    const recordsInArray = Object.values(_records);
    return recordsInArray.map((pomoItem: IPomoItem) => {
      // console.log(pomoItem)
      return (
        <Fragment key={pomoItem.id}>
          <PomoTimerRow
            // key={pomoItem.id}
            tasks={tasks}
            // tasksStrings={tasksStrings}
            // tasksIds={tasksIds}
            curTaskId={pomoItem.id}
            latestPomoTimerId={latestPomoTimerId}
            size={"small"}
            disableXButton={disableXButton(_records)}
            disableStartButton={true} // Disables
            records={records}
            setRecords={setRecords}
            uploadPomoRecord={(_records: IPomoRecords, curTaskId: number, setRecords: React.Dispatch<React.SetStateAction<IPomoRecords>>) =>
              uploadPomoRecord(_records, curTaskId, setRecords)
            }
          />
          <hr />
        </Fragment>
      )
    });
  };

  const renderLatestPomoTimer = (_records: IPomoRecords) => {
    // Returns record with latest curTaskId( latest task id)
    // const curTaskId = _records[0] && _records[0].id; // Is this best way? maybe by id being biggest?

    // Decides here if X button should be disable or not. Will always keep last one.
    return (
      <PomoTimerRow
        tasks={tasks}
        // tasksStrings={tasksStrings}
        // tasksIds={tasksIds}
        curTaskId={latestPomoTimerId(_records)}
        latestPomoTimerId={latestPomoTimerId}
        size={"middle"}
        disableXButton={disableXButton(_records)}
        records={records}
        setRecords={setRecords}
        uploadPomoRecord={(_records: IPomoRecords, curTaskId: number, setRecords: React.Dispatch<React.SetStateAction<IPomoRecords>>) =>
          uploadPomoRecord(_records, curTaskId, setRecords)
        }
      />
    );
  };

  const renderPomoTimerList = () => {
    /* Always make sure there is 1 item to render */
    // Typescript complains about tunary stuff... adding logic here instead

    if (Array.isArray(currentRecordsIds) && currentRecordsIds.length === 0) {
      // records should always be empty. But adding here will make it more obvious/easier to debug if there is an issue.
      setRecords({ ...records, ...emptyRecord() });
    }

    if (Array.isArray(currentRecordsIds) && currentRecordsIds.length) {
      // To fix double state of main footbar and drawer showing twice out of sync, always remove latest on drawer.
      const _latestPomoTimerId = latestPomoTimerId(records);
      const { [_latestPomoTimerId]: _, ...rest } = records;

      return renderAllPomoTimer(rest);
    }

    // Should not end up here. Can end up here if records state becomes empty somewhere...
    return "";
  };

  // Dev menu - conditional
  const renderDevMenu = () => {
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.REACT_APP_ENV_DEBUG_BARS === "true" &&
      process.env.REACT_APP_ENV_DEBUG_BAR_FOOTER === "true"
    ) {
      return (
        <div className="bg-gray-400">
          <div className="font-bold italic mb-2">
            <div>Dev Menu (Footer)</div>
            {devBtnWithSampleData()}
          </div>
          <div>
            Record IDs (len: {currentRecordsIds.length}):{" "}
            {JSON.stringify(currentRecordsIds, null, 2)}
          </div>
          <div>{JSON.stringify(records, null, 2)}</div>
        </div>
      );
    }
  };

  // Dev button for fast data generation
  const devBtnWithSampleData = () => {

    // setRecords(sampleFooterTaskData)
    // setRecords({...records, ...sampleFooterTaskData})
    // setRecords({ ...records, ...emptyRecord() });
    return (
      <>
        <Button size="small" onClick={() => setRecords(sampleFooterTaskData)}>
          Force (reset) to Sample Data
        </Button>
      </>
    );
  };

  const renderDrawerTitle = () => {
    // TEMP: size here is disconnected to rest of pomo.
    const size = "small";
    return (
      <div className="flex flex-row items-center">
        <div className="mr-3">All pomodoros -</div>
        <div className="flex flex-row items-center">
          {/* <Input
            className="mr-1"
            placeholder="Change All Empty"
            onChange={e => {
              const taskId = tasks.filter(task => task.name === e.target.value)
              console.log(taskId)
              setAllEmptyInput({
                ...allEmptyInput,
                task: e.target.value,
                taskId: 1
              })
              console.log(allEmptyInput)
              console.log(tasks)
            }}
            value={allEmptyInput.task}
          /> */}
          <AutoComplete
            size={size}
            style={size === "small" ? { width: 160 } : { width: 180 }}
            options={namesAndIds_ToAutoCompleteOptions(tasks, []) as any}
            placeholder="Tasks"
            filterOption={(inputValue, option) =>  // @ts-ignore
              option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onChange={(value, option: any) => {
              setAllEmptyInput({
                ...allEmptyInput,
                task: value,
                taskId: option.taskid,
              });
            }}
            value={allEmptyInput.task}
            className="mr-2"
          />
          <Button
            shape="round"
            icon={<ScanOutlined />}
            disabled={allEmptyInput.taskId ? false : true}
            onClick={() => {
              // Validation handled at button level. Invalid, this button is disabled.

              // Get all records (in array format instead of object)
              const recordFilledVals = Object.values(records);

              // Fill all empty record or pass previous record
              const recordsFilledArray = recordFilledVals.map((record) => {
                return {
                  ...record,
                  task: record.task ? record.task : allEmptyInput.task,
                  taskId: record.taskId ? record.taskId : allEmptyInput.taskId,
                };
              });

              // Records filled in correct format
              const recordsFilledObj = recordsFilledArray.reduce(
                (acc, record) => ({ ...acc, [record.id]: record }),
                {}
              );
              setRecords(recordsFilledObj);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSWRLoadStatus = () => {
    // isLoading, isError
    if (isLoading) {
      return (
        <div
          className="animate-pulse flex-none w-3 h-3 mr-1 bg-yellow-300 rounded"
          title="Loading tasks..."
        ></div>
      );
    }
    if (isError) {
      return (
        <div
          className="animate-pulse flex-none w-3 h-3 mr-1 bg-red-300 rounded"
          title="Error loading tasks"
        ></div>
      );
    }
    // invisible to keep taking width.
    return (
      <div className="invisible flex-none w-3 h-3 mr-1 bg-green-300 rounded"></div>
    );
  };

  return (
    <div className="bg-gray-400 px-2">
      {renderDevMenu()}
      <div className="flex flex-row items-center">
        {/* <div>
          <Spin size="small" delay={200} spinning={true} />
          {isValidating && <MinusCircleOutlined spin />}
        </div> */}
        {renderSWRLoadStatus()}
        <div className="">
          {/* Checks for empty array. && gives 0 for length so need to be like below. */}
          {Array.isArray(currentRecordsIds) && currentRecordsIds.length
            ? renderLatestPomoTimer(records)
            : ""}
        </div>

        <div className="ml-auto flex flex-row">
          <Badge count={anyRecordValid(records) ? Object.keys(records).length-1 : 0} >
            <Button
              shape="round"
              icon={<UploadOutlined />}
              disabled={!anyRecordValid(records)}
              // onClick={() => uploadAllPomoRecord(records)}
              onClick={() => uploadAllPomoRecordOrdered(records)}
            >
              Upload All
            </Button>
          </Badge>
          <Button
            className={`
              ml-2 hover:animate-bounce
            bg-gray-400 border-gray-400
            hover:border-gray-400 hover hover:text-orange-600`}
            type="ghost"
            shape="circle"
            icon={<UpOutlined />}
            onClick={showDrawer}
          />
          <Drawer
            className=""
            bodyStyle={{ backgroundColor: "#cbd5e0" }}
            headerStyle={{ backgroundColor: "#cbd5e0" }}
            height="60%" // 256 is default
            title={renderDrawerTitle()}
            // title="All pomodoros"
            placement="bottom"
            closable={false}
            onClose={onClose}
            visible={visible}
            // visible={true}
          >
            {renderPomoTimerList()}
          </Drawer>
        </div>
      </div>
    </div>
  );
};
