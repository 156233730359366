import React, { useEffect, useState } from "react";
import { Switch, Select, Menu, Dropdown, Tag } from "antd";
import { DownOutlined, ExpandOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  createProjectsList,
  listToAutoCompleteOptions,
} from "../../select/selectSWR";
import { appGlobalUserSlice, AppGlobalUser } from '../../store/appGlobalUser';
import { RootState } from "../../store/store";
import { useProject } from "../table/projectSWR";
import { KanbanContainer } from '../KanbanContainer';

const { SubMenu } = Menu;

const PROJECT_FILTER = "kanx-config--kanbanBoard--project-filter"  // easier possibility in future to pull this out and have localstorage datastructure

interface Props {}

export const KanbanMenuBar: React.FC<Props> = () => {
  const {
    data: projectData,
    isLoading: projectDataIsLoading,
    isError: projectDataIsError,
  } = useProject();

  const [projectFilter, setProjectFilter] = useState<string[][]>([]);
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);

  const projects = projectData?.results;
  const projectsList = projectDataIsLoading ? [] : createProjectsList(projects);

  const projectList_LocalStorage_raw = localStorage.getItem(
    PROJECT_FILTER
  );
  const projectList_LocalStorage = projectList_LocalStorage_raw
    ? JSON.parse(projectList_LocalStorage_raw)
    : [];

  useEffect(() => {  }, [projectFilter]);

  const renderFilterTags = () => {
    console.log(projectList_LocalStorage);
    
    const tags = projectList_LocalStorage.map((projectFilter: string[]) => {
      const projectFilterString = projectFilter.join(', ')
      const projectSelected = JSON.stringify(projectFilter.sort()) === JSON.stringify([...appGlobalUser.kanbanProjectFilter].sort())
      return (
      <Tag
        key={projectFilterString}
        color={`${projectSelected ? "#108ee9" : "#2db7f5"}`}
        className="cursor-pointer m-1"
        onClick={() =>
          dispatch(
            appGlobalUserSlice.actions.kanbanProjectFilter(projectFilter)
          )
        }
      >
        {projectFilterString}
      </Tag>
    )});
    return tags;
  };

  const renderMenu = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Projects Filter">
          <Menu.Item
            key={'projects-filter--save'}
            onClick={() => {

              /*
              Save items to localStorage.
              Maybe pull out Array of Array comparison as utility function
              */
              // ignore if it's empty array
              console.log(`appGlobalUser.kanbanProjectFilter.length: ${appGlobalUser.kanbanProjectFilter.length}`)
              if (!appGlobalUser.kanbanProjectFilter.length) return;

              // Forces new array to always be sorted. Have to clone it first.
              const kanbanFilters = [
                ...projectList_LocalStorage,
                [...appGlobalUser.kanbanProjectFilter].sort(),
              ];

              // Array of Array comparison
              // using one liner from here - https://stackoverflow.com/questions/44014799/javascript-how-to-remove-duplicate-arrays-inside-array-of-arrays
              let t: any;
              const kanbanFiltersUnique = kanbanFilters.filter(( t={}, a=> {const b = a.sort(); return !(t[b]=b in t)} ));

              localStorage.setItem(
                PROJECT_FILTER,
                JSON.stringify(kanbanFiltersUnique)
              );

              setProjectFilter(kanbanFiltersUnique)  // forces refresh of component
            }}
          >
            Save
          </Menu.Item>
          <Menu.Item
            key={'projects-filter--clear'}
            onClick={() => {
              // localStorage.setItem(PROJECT_FILTER, [])
              localStorage.removeItem(PROJECT_FILTER)
              dispatch(appGlobalUserSlice.actions.kanbanProjectFilter([]));  // Empty structure, effectively clearing up any items.
            }}
          >
            Clear
          </Menu.Item>
        </Menu.ItemGroup>
        <SubMenu key={'other-items'} title="Other items">
          <Menu.Item key={'other-items-1'}>AAA</Menu.Item>
          <Menu.Item key={'other-items-2'}>BBB</Menu.Item>
        </SubMenu>
        <SubMenu key={'other-sub-menu'} title="disabled sub menu" disabled>
          <Menu.Item key={'other-items-3'}>5d menu item</Menu.Item>
          <Menu.Item key={'other-items-4'}>6th menu item</Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  return (
    <div className="flex flex-row items-center content-center ">
      <div className="ml-3 mt-1">
        <Switch
          size="small"
          checked={appGlobalUser.hideArchived}
          onChange={() =>
            dispatch(
              appGlobalUserSlice.actions.hideArchived(
                !appGlobalUser.hideArchived
              )
            )
          }
          title="Show archived only?"
          checkedChildren="current" // Shows archived only
          unCheckedChildren="archived"
        />
      </div>
      <div className="ml-3 mt-1">
        <Select
          loading={projectDataIsLoading}
          mode="multiple"
          style={{ width: 420 }} // Need to figure out better responsive way
          showSearch
          // bordered={false}
          allowClear
          placeholder="Project"
          options={listToAutoCompleteOptions(projectsList) as any}
          onChange={(value) => {
            dispatch(appGlobalUserSlice.actions.kanbanProjectFilter(value));
          }}
          // onChange={(value) => { console.log(value); console.log(projectsList); console.log(listToAutoCompleteOptions(projectsList)) }}
          value={appGlobalUser.kanbanProjectFilter}
        />
      </div>
      <Dropdown
        className="ml-2 border-b-2 border-gray-400"
        overlay={renderMenu}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Menu <DownOutlined />
        </a>
      </Dropdown>
      <div className="ml-2">
        {renderFilterTags()}
      </div>
    </div>
  );
};
