import { Button } from "antd";
import React, { Dispatch, useReducer, useState } from "react";
import { removeById } from "../../util/utils";
import { PomoTimer } from "./PomoTimer";
import { IPomoRecords, IPomoItem } from "./FooterContainer";
import { Task } from "../../store/task";

import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { appGlobalUserSlice } from "../../store/appGlobalUser";

// Like pomoTimer but contains UI part for delete, and upload with extra buttons.
// pomoTimer itself is complex enough I don't want extra button logics in there.

interface Props {
  tasks: Task[];
  // tasksStrings: string[]; // All tasks names for the current kanban board Same order as Ids
  // tasksIds: number[]; // All tasks id for current kanban board. Same order as Strings
  autoStart?: boolean;
  curTaskId: number;
  size?: "small" | "middle"; // Size for componts. Matching antd naming convention.
  disableStartButton?: boolean;

  disableXButton?: boolean; // Is current task latest?

  latestPomoTimerId: (_records: IPomoRecords) => number;
  records: IPomoRecords;
  setRecords: Dispatch<any>;

  uploadPomoRecord: (_records: IPomoRecords, _curTaskId: number, setRecords: React.Dispatch<React.SetStateAction<IPomoRecords>>) => void;
}

/*
No logic here. All button logic done in loop inside footer
*/

export const PomoTimerRow: React.FC<Props> = ({
  tasks,
  // tasksStrings,
  // tasksIds,
  autoStart,
  curTaskId,
  size,
  records,
  disableXButton,
  disableStartButton,

  latestPomoTimerId,
  setRecords,
  uploadPomoRecord,
}) => {
  const reduxDispatch = useDispatch();

  const validateTaskForUpload: any = () => {
    // used for state of upload button, to check if there is taskId, start and end
    return (
      records[curTaskId].start &&
      records[curTaskId].end &&
      records[curTaskId].taskId
    );
  };

  return (
    <div className="flex flex-row items-center">
      <PomoTimer
        tasks={tasks}
        // tasksStrings={tasksStrings}
        // tasksIds={tasksIds}
        curTaskId={curTaskId}
        autoStart={autoStart}
        size={size}
        disableStartButton={disableStartButton}
        latestPomoTimerId={latestPomoTimerId}

        records={records}
        setRecords={setRecords}
      />
      <Button
        className="mx-2"
        // Only allow upload when there is start and end
        shape="circle"
        danger={validateTaskForUpload()}
        disabled={!validateTaskForUpload()}
        onClick={() => uploadPomoRecord(records, curTaskId, setRecords)}
        icon={<UploadOutlined />}
      ></Button>

      {/* Always enable X button and let auto state always have 1 ? */}
      {/* {!disableXButton && (
        <Button
          danger
          shape="circle"
          disabled={disableXButton}
          onClick={() => setRecords(removeById(records, curTaskId))}
        >
          X
        </Button>
      )} */}

      {/* always enable X. A way of clearing state by destroying and creating new */}
      <Button
        danger
        shape="circle"
        onClick={() => {
          setRecords(removeById(records, curTaskId));
          // When removing pomodoro remove selected state in global kanbanboard task id for highlight.
          // Set globalSelectedTaskId to 0 so it matches nothing
          if (latestPomoTimerId(records) === curTaskId) {
            reduxDispatch(appGlobalUserSlice.actions.globalSelectedTaskId(0));
          }
        }}
      >
        X
      </Button>
    </div>
  );
};
