import { store } from "../store/store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import { appLoadingSlice } from "../store/appLoading";
import {
  _fetchBoardList,
  BoardLite,
  _fetchBoardFullList,
  BoardFullResponse,
} from "../store/boardList";
import { categorySlice, _fetchCategories } from "../store/category";
import { projectSlice, _fetchProjects } from "../store/project";
import { recordSlice, _fetchRecords } from "../store/record";
import { taskSlice, _fetchTasks } from "../store/task";
import log from "loglevel";
import { _fetchColumnTask } from "../store/column";

export const fetchAll = createAsyncThunk("helper/fetchAll", async () => {
  store.dispatch(_fetchBoardList());
  // store.dispatch(_fetchBoardFullList(needID!)); // Need ID
  store.dispatch(_fetchTasks());
  store.dispatch(_fetchCategories());
  store.dispatch(_fetchRecords());
  store.dispatch(_fetchProjects());
  return [];
});

export const fetchInitial = createAsyncThunk(
  "helper/fetchInitial",
  async () => {
    store.dispatch(fetchBoardList());
    // store.dispatch(_fetchTasks());
    // store.dispatch(_fetchCategories());
    // store.dispatch(_fetchRecords());
    // store.dispatch(_fetchProjects());
    return [];
  }
);

export const loadingAll = createAsyncThunk(
  "helper/loadingAll",
  async (status: boolean) => {
    store.dispatch(appLoadingSlice.actions.boardList(status));
    store.dispatch(appLoadingSlice.actions.task(status));
    store.dispatch(appLoadingSlice.actions.category(status));
    store.dispatch(appLoadingSlice.actions.record(status));
    store.dispatch(appLoadingSlice.actions.project(status));
    return [];
  }
);

// Does extra to fetch that (maybe) required. _fetchBoardList only does fetching.
// I think appLoadingSlice "extraReducers" are changing loading state on "pending", "fulfilled".
export const fetchBoardList = createAsyncThunk("boardList/fetch", async () => {
  store.dispatch(_fetchBoardList());
  // .then(() => store.dispatch(boardListSlice.actions.nuke()));
});

//TODO: Move rejects to somewhere else (inside of appropiate sliceCreater?)
// Whole flow to load in app. Boards, Column
export const fetchInitialKanbanList = createAsyncThunk(
  "initial/fetchKanbanList",
  async () => {
    // 1. Fetch Board List. (Lite and fast version)
    store.dispatch(_fetchBoardList()).then((res) => {
      // 2. Fetch each detailed version of board using ID of boards previous data
      // TODO: Show archieve through global toggle state.
      if (res.type === "boardList/_fetch/rejected") {
        log.warn(
          `Can not fetch "fetchInitialKanbanList" boardList/_fetch/rejected. Is server down?`
        );
        message.error(
          `Can not fetch "fetchInitialKanbanList" boardList/_fetch/rejected. Is server down?`
        );
        log.debug(res);
      }
      if (res.type === "boardList/_fetch/fulfilled") {
        log.info(`fetchInitialKanbanList - 1._fetchBoardList done.`);
        const boards: BoardLite[] = res.payload;

        // Set all boards loading to true
        boards.map((board) => store.dispatch(appLoadingSlice.actions.boardListFull({id: board.id, loading: true})))
        // const boardsIDs = boards.map((board) => board.id)
        // boardsIDs.map((id) => store.dispatch(appLoadingSlice.actions.boardListFull({id: id, loading: false})))

        boards.map((board): void => {
          store.dispatch(_fetchBoardFullList(board.id)).then((resFetchBoardFullList) => {
            // 2. Fetch each column info (ids) to be able to load fully
            if (resFetchBoardFullList.type === "boardListFull/_fetch/rejected") {
              log.warn(
                `Can not fetch "fetchInitialKanbanList" boardListFull/_fetch/rejected. Is server down?`
              );
              message.error(
                `Can not fetch "fetchInitialKanbanList" boardListFull/_fetch/rejected. Is server down?`
              );
              log.debug(resFetchBoardFullList);
            }
            if (resFetchBoardFullList.type === "boardListFull/_fetch/fulfilled") {
              log.info(`fetchBoardListFull - 2._fetchBoardFullList done.`);
              store.dispatch(appLoadingSlice.actions.boardListFull({id: board.id, loading: false})) // Board loaded. Setting loading to false

              // 3. Fetch column and tasks on it through column IDs
              const { id, response: boardFull } = resFetchBoardFullList.payload as BoardFullResponse;

              // Set all colunmnTask loading to true
              boardFull.columns.map((column) => store.dispatch(appLoadingSlice.actions.columnTask({id: column.id, loading: true})))

              boardFull.columns.map((column): void => {
                log.debug(`fetchBoardListFull - 2._fetchBoardFullList done.`);

                store.dispatch(_fetchColumnTask(column.id)).then((res) => {
                  if (res.type === "columnTask/_fetch/rejected") {
                    log.warn(
                      `Can not fetch "fetchInitialKanbanList" columnTask/_fetch/rejected. Is server down?`
                    );
                    message.error(
                      `Can not fetch "fetchInitialKanbanList" columnTask/_fetch/rejected. Is server down?`
                    );
                    log.debug(res);
                  }
                  if (res.type === "columnTask/_fetch/fulfilled") {
                    log.info(`Yay! loaded column ${column.id}.`)
                    store.dispatch(appLoadingSlice.actions.columnTask({id: column.id, loading: false}))
                  }
                })
              })
            }
          });
        });
      }
    });
  }
);
