import React from "react";
import { MoodList } from "./mood/MoodList";
import { CenteredVerHor } from './style/CenteredVerHor';
import { MainLayout } from "./common/MainLayout";
import { MoodAdd } from "./mood/MoodAdd";
import { Divider } from "antd";

interface Props {}

export const MoodContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <div className="px-3">
        <MoodAdd />
        <Divider />
        <MoodList />
      </div>
    </MainLayout>
  );
};
