import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import log from 'loglevel';

import { URLS } from '../UrlConst';
import { PomoCategories, DjangoPagination } from './typesShared';

export interface Record {
  id: number;
  start_time: string;
  end_time: string;
  task: number;
  task_name: string;
  type: PomoCategories;
  comment: string;
  owner: number;
}


// *** DEPRECATED IN FAVOUR IS SWR
export interface RecordResponse extends DjangoPagination {
  results: Record[];
}

const initialState: Record[] = [];

export const _fetchRecords = createAsyncThunk(
  'record/_fetch',
  async () => {
    const response = await axios.get(URLS.record);
    return response.data
  }
)

export const recordSlice = createSlice({
  name: 'record',
  initialState: initialState,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      return [];
    }
    // fetch: ,
    // No remove, or update currently (on backend also?)
  },
  extraReducers: {
    [_fetchRecords.fulfilled.type]: (state, action: PayloadAction<RecordResponse>) => {
      log.info(`${action.type} - ${URLS.record}`);
      log.debug(action.payload);
      const records = action.payload.results;
      state.push(...records);
    },
    [_fetchRecords.pending.type]: (_state, action) => {
      log.info(action.type)
    }
  }
})