import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import log from "loglevel";
import { Project, ProjectResponse } from "../components/table/projectSWR";

import { URLS } from "../UrlConst";

// *** DEPRECATED IN FAVOUR IS SWR
const initialState: Project[] = [];
// FUTURE: I think this is not used and not working. Try in devContainer and there's error.
// Check again git history. I touched code and I think below is what it use to be but still error.
export const _fetchProjects = createAsyncThunk("project/_fetch", async () => {
  // try {
  //   const response = await axios.get(URLS.project);
  //   return response.data;
  // } catch (err) {
  //   console.error("Unable to fetch projects ", err);
  // }

  const response = await axios.get(URLS.project);

  // NOTE: Watchout once I start using pagination.
  if (response["data"]["next"] != null) {
    console.warn(`
      There is unhandled pagination of projects.
      Please fix the code to take into account for pagination.
      Currently it will only show first page`);
  }
  return response.data; // change in format due to pagination turned on.
});

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      return [];
    },
    // remove: ,
    // update: ,
  },
  extraReducers: {
    [_fetchProjects.fulfilled.type]: (
      state,
      // action: PayloadAction<Project[]>
      action: PayloadAction<ProjectResponse>
    ) => {
      log.info(`${action.type} - ${URLS.project} `);
      log.debug(action.payload);
      state.push(...action.payload.results);
    },
    [_fetchProjects.pending.type]: (_state, action) => {
      log.info(action.type);
    },
  },
});
