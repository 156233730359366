import React from "react";
import { Layout } from "antd";
import { NavSider } from "./NavSider";
import { FooterContainer } from "../timer/FooterContainer";

const { Content, Sider, Footer } = Layout;

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Layout className="min-h-full">
      <Sider
        // TOOD: May need to enable below including porting over sider-config css for fully mobile responsive (like previously)
        // className="sider-config"

        // Hidden info on how to do this. Combo below makes auto phone nav if needed.
        collapsedWidth={0} // Default is 80 but at 0 it shows hamburger menu.
        collapsible={false} // Shows menu to collapse always. False with other combos make it show only for mobile view
        defaultCollapsed={false}
        breakpoint="sm"
      >
        <NavSider />
      </Sider>
      <Layout>
        <Content>{children}</Content>
        <Footer className="p-0 bottom-0">
          <FooterContainer />
        </Footer>
      </Layout>
    </Layout>
  );
};
