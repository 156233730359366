import React, { useState } from "react";
import { Spin, Table, Card, Badge } from "antd";
import { ColumnsType } from "antd/es/table";
import { useRecordSWR, Record } from "./recordSWR";
import {
  parseISO,
  format,
  differenceInMinutes,
  differenceInDays,
} from "date-fns";
import { CenteredVerHor } from "../style/CenteredVerHor";
import DatePicker from "../DatePicker";
import { RangeValue } from "rc-picker/lib/interface";
import { AxiosRequestConfig } from "axios";
import { PomoCategories } from "../../store/typesShared";
import { UsefulDates, ranges } from '../../util/dateUtils';
import { createDateStringForParam } from "../../util/swrHelper";
import { formatDuration } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { appGlobalUserSlice } from '../../store/appGlobalUser';

const { RangePicker } = DatePicker;

interface RecordRow {}

// TODO: Add (generate) date filter. Add sorter. (Also for start time)
// used ID as default to order. Since new record (almost always) = newer time this is quick easy way to get right order of record from newest.
const columns: ColumnsType<RecordRow> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    defaultSortOrder: "descend",
    sorter: (a: any, b: any) => a.id - b.id,
  },
  //  {
  //   title: 'TaskID',
  //   dataIndex: 'task',
  //   key: 'task',
  // },
  {
    title: "Task",
    dataIndex: "task_name",
    key: "task_name",
    sorter: (a: any, b: any) => a.task_name.localeCompare(b.task_name),
  },
  {
    title: "Date",
    dataIndex: "calc_date",
    key: "calc_date",
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    key: "start_time",
    // defaultSortOrder: 'ascend',
    // sorter: (a, b) => moment(a.start_time) - moment(b.start_time), // Have to do moment diff and return -1 or 0? Depending on positive or negative.
  },
  {
    title: "End Time",
    dataIndex: "end_time",
    key: "end_time",
  },
  {
    title: "Length",
    dataIndex: "calc_length",
    key: "calc_length",
    sorter: (a: any, b: any) => a.calc_length - b.calc_length,
  },
  {
    title: "Pomodoro type",
    dataIndex: "type",
    key: "type",
    sorter: (a: any, b: any) => a.type.localeCompare(b.type),
    // All types, look at Task model in django.
    filters: [
      {
        text: "Pomodoro",
        value: "Pomodoro",
      },
      {
        text: "Work",
        value: "Work",
      },
      {
        text: "Non-Pomodoro",
        value: "Non-Pomodoro",
      },
      {
        text: "Goal",
        value: "Goal",
      },
    ],
    onFilter: (value, row: any) => row.type.indexOf(value) === 0,
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
];

// Specific to this data structure
const sumPomoByCategory = (
  records: Record[],
  filterKey: PomoCategories
): number => {
  const filteredRecord = records.filter((record) => record.type === filterKey);
  return filteredRecord.length;
};

const sumBadgeStyle = { backgroundColor: "#2db7f5" };

interface Props {}

export const RecordTable: React.FC<Props> = () => {
  const usefulDates = new UsefulDates();

  // used for RangePicker
  // const [startDate, setStartDate] = useState<Date>(usefulDates.today);
  // const [endDate, setEndDate] = useState<Date>(usefulDates.tomorrow);

  // used for RangePicker Redux Hook version
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);
  const startDate = parseISO(appGlobalUser.recordTableStartDate);
  const endDate = parseISO(appGlobalUser.recordTableEndDate);


  // used for making param to be send to API server
  const [config, setConfig] = useState<AxiosRequestConfig>({
    params: {
      start: createDateStringForParam(startDate),
      end: createDateStringForParam(endDate),
    },
  });

  // TODO: Add date to get. Today with + - some hours to capture whole day hopefully.
  // TODO: Add default time that can be changed.
  const { data, isLoading, isError } = useRecordSWR(config);
  // const { results, next, previous, count} = data;

  const next = data?.next;
  const previous = data?.previous;
  const count = data?.count;
  const results = data?.results;
  const records = results;

  // console.log(isLoading)
  // console.log(records)

  const recordWithBetterDates = (_records: Record[]) => {
    // Changes timestamp to better format.
    const recordsUpdated = _records.map((record: Record) => {
      const startDateTime = parseISO(record.start_time);
      const endDateTime = parseISO(record.end_time);

      const _recordUpdated = {
        ...record,
        start_time: `${format(startDateTime, "hh:mm:ss a")}`,
        end_time: `${format(endDateTime, "hh:mm:ss a")}`,
        calc_date: `${format(startDateTime, "EEEE - yyyy-MM-dd")}`,
        calc_length: `${differenceInMinutes(endDateTime, startDateTime)}`,
      };

      return _recordUpdated;
    });
    return recordsUpdated;
  };

  const rangePickerChange = (date: RangeValue<Date>, dateString: string[]) => {
    // Return if there is no date (will not be case from antd? but seems to need this for typescript)
    if (!date) {
      return;
    }

    // Take moments time object
    const _startDate = date[0] as Date;
    const _endDate = date[1] as Date;

    // Change UI
    // setStartDate(_startDate);
    // setEndDate(_endDate);
    // Date is not serializable. Redux recommends serializable data.
    dispatch(appGlobalUserSlice.actions.recordTableStartDate(_startDate.toISOString()))
    dispatch(appGlobalUserSlice.actions.recordTableEndDate(_endDate.toISOString()))

    // Change to format backend (requires string of YYYY-MM-DD HH:MM[:ss[.uuuuuu]][TZ] format)
    setConfig({
      params: {
        start: createDateStringForParam(_startDate),
        end: createDateStringForParam(_endDate),
      },
    });

    // this.setState({
    //   start_time: start_time,
    //   end_time: end_time
    // }, () => { this.calculateDateDiff() })
  };

  // Using built in loading for table
  // if (isLoading)
  //   return (
  //     <CenteredVerHor>
  //       <Spin size="large" />
  //       <div>Loading records data...</div>
  //     </CenteredVerHor>
  //   );

  // TODO: Improve this.
  // Causes crash when no data.
  // if (isError)
  //   return (
  //     <CenteredVerHor>
  //       {/* Sadly doesn't actually freeze spinning but disapears */}
  //       <Spin size="large" spinning={false} />
  //       <div>Whaaat? There is an error</div>
  //     </CenteredVerHor>
  //   );

  ///
  // Process some display info before render.
  const differenceInDaysOf_DateRange = differenceInDays(endDate, startDate);
  const tableData = (isLoading || !records) ? [] : recordWithBetterDates(records);

  // Return 0 while it's loading or error (if error after data's loaded, it'll use previous data meaning keep number same)
  const sumRecord_Pomo = records ? sumPomoByCategory(records, "Pomodoro") : 0;
  const sumRecord_Work = records ? sumPomoByCategory(records, "Work") : 0;
  const sumRecord_Goal = records ? sumPomoByCategory(records, "Goal") : 0;
  const sumRecord_All = sumRecord_Pomo + sumRecord_Work + sumRecord_Goal
    // records && sumRecord_Pomo + sumRecord_Work + sumRecord_Goal;
    // also records.length will give same for this as 1 row = 1 pomo

  return (
    <div className="px-3">
      <h1 title="Filters date between start 4am to nextday 4am. Tasks falling between those times will be shown.">
        Your Day Record
      </h1>
      <div className="flex flex-row">
        <Card title="Pomo" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={sumRecord_Pomo}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <Card title="Work" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={sumRecord_Work}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <Card title="Goal" className="mx-1 w-32 text-center">
          <Badge
            title="Total"
            count={sumRecord_Goal}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
        <div className="flex flex-row items-center mx-2">=</div>
        <Card title="All" className="mx-1 w-32 text-center bg-indigo-200">
          <Badge
            title="Total"
            count={sumRecord_All}
            style={sumBadgeStyle}
            showZero
            overflowCount={99999}
          />
        </Card>
      </div>
      <div className="my-3">
        <Badge
          title={`This is ${formatDuration({
            days: differenceInDaysOf_DateRange,
          })}`}
          count={differenceInDaysOf_DateRange}
          style={{
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset",
          }}
          size="default"
          offset={[5, 0]}
        >
          <RangePicker
            ranges={ranges()}
            showTime
            format="yyyy-MM-dd HH"
            value={[startDate, endDate]}
            onChange={(date, dateString) => rangePickerChange(date, dateString)}
          />
        </Badge>
      </div>
      <Table
        rowKey="id"
        // dataSource={records}
        loading={isLoading}
        dataSource={tableData}
        columns={columns}
        pagination={{
          position: ["bottomLeft", "topLeft"],
          pageSize: 100,
          showSizeChanger: false,
          hideOnSinglePage: true,
          simple: false,
          total: count,
          onChange: (page, pageSize) => {setConfig({...config, params: {...config.params, page:page}})}
        }}
        size="small"
        // bordered
      />
    </div>
  );
};
