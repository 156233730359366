import React from "react";
import { MainLayout } from "./common/MainLayout";
import { ProjectTable } from './table/ProjectTable';

interface Props {}

export const ProjectTableContainer: React.FC<Props> = () => {
  return (
    <MainLayout>
      <ProjectTable />
    </MainLayout>
  );
};
