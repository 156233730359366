import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import log from "loglevel";
import thunk from "redux-thunk";
import { URLS } from "../UrlConst";

export interface authUser {
  id: number;
  username: string;
}

export interface auth {
  token?: string;
  user?: authUser;
}

const initialState: auth = {};

export const _authLogin = createAsyncThunk(
  "auth/_login",
  async (
    data: {
      username: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    const { username, password } = data;
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify({ username, password });

    // With fetch
    const response = await fetch(`${URLS.login}/`, {
      headers,
      body,
      method: "POST",
    });
    if (response.status === 401) {
      // Explicitly trigger redux reject flow
      return rejectWithValue(await response.json());
    }
    return response.json();

    // With Axios (also works fine but need bit of different logic)
    // try {
    //   const response = await axios.post(`${URLS.login}/`, {username, password})
    //   console.log(response)
    //   return response.data
    // } catch (err) {
    //   if (!err.response) {
    //     throw err
    //   }
    //   return rejectWithValue(err.response.data)
    // }
  }
);

// Used for checking if current token is valid
export const _authUserAPI = createAsyncThunk(
  "auth/_userAPI",
  async (
    data: {
      token: string;
    },
    { rejectWithValue }
  ) => {
    const { token } = data;
    const headers = { "Authorization": `Token ${token}` };
    const response = await fetch(`${URLS.userAPI}/`, {
      headers,
      method: "GET",
    });
    // FUTURE: Check what response is by uncommenting console.log on live server.
    // Locally sending when server is down gets COR error on browser
    // and see if sending userAPI when server is down causes 401 issue of nuking (when it should just not do anything)
    // so user can keep using and send later when sever is up.

    // console.log('_authUserAPI sent with')
    // console.log(headers)

    if (response.status === 401) {
      // Explicitly trigger redux reject flow
      // console.log(response)
      return rejectWithValue(await response.json());
    }
    return response.json();
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // Manually clear auth state
    nuke: (state, action: PayloadAction<void>) => {
      log.info(`${action.type}`);
      localStorage.removeItem('kanx-token')
      localStorage.removeItem('kanx-user')
      delete axios.defaults.headers.common['Authorization'];
      return {};
    },
    // Manually set auth state. LocalStorage, axios header, redux state.
    setToken: (state, action: PayloadAction<string>) => {
      log.info(`${action.type}`);
      const { payload: token } = action;
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      state.token = token;
    },
    // Second part of above. Set user (user info from backend)
    setUser: (state, action: PayloadAction<authUser>) => {
      log.info(`${action.type}`);
      const { payload: user } = action;
      state.user = user;
    },

    // fetch: ,
    // No remove, or update currently (on backend also?)
  },
  extraReducers: {
    [_authLogin.fulfilled.type]: (
      state,
      action: PayloadAction<{
        token: string;
        user: { id: number; username: string };
      }>
    ) => {
      log.info(`${action.type} - ${URLS.auth}`);
      log.debug(action.payload);
      const { token, user } = action.payload;

      localStorage.setItem('kanx-token', token);
      localStorage.setItem('kanx-user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;

      // console.log("success auth login");
      // console.log(action.payload);
      // console.log("state");
      // console.log(state);

      // Two below commented out does not work due to... some way RTK mutation package thing works.
      // state = {token: token, user: user}
      // state = action.payload;

      // This works to mutation.
      state.token = token;
      state.user = user;
    },
    [_authLogin.pending.type]: (_state, action) => {
      log.info(action.type);
    },
    [_authLogin.rejected.type]: (state, action) => {
      log.info(action.type);
      // Bit of weird name from DjangoRest. Probably possible to fix/change.
      console.log(action.payload.non_field_errors);
      message.error(`Error logging in - ${action.payload.non_field_errors}`);
    },


    // handles 401 case. In case token is invalid, user will lose redux auth state causing app to go to login screen only
    [_authUserAPI.rejected.type]: (state, action) => {
      log.info(action.type);
      console.log(action.payload);
      message.error(`Could not validate token (401 or other error).`);

      // Remove auth token in localStorage, Axios header, .
      localStorage.removeItem('kanx-token')
      localStorage.removeItem('kanx-user')
      delete axios.defaults.headers.common['Authorization'];
      return {};
    },
  },
});
