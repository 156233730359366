import React from "react";
import { MainLayout } from "./common/MainLayout";
import { KanbanBoard } from "./kanban/KanbanBoard";
import { CenteredVerHor } from "./style/CenteredVerHor";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Spin } from "antd";

interface Props {}

export const KanbanContainer: React.FC<Props> = () => {
  const appLoading = useSelector((state: RootState) => state.appLoading);

  const { boardList: boardListisLoading } = appLoading;

  if (boardListisLoading)
    return (
      <MainLayout>
        <CenteredVerHor>
          <Spin size="large" />
          <div>Loading KanbanBoards...</div>
        </CenteredVerHor>
      </MainLayout>
    );

  return (
    <MainLayout>
      <KanbanBoard />
    </MainLayout>
  );
};
