import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Loading } from '../store/appLoading';
import log from 'loglevel';

export const arrloadingChecker = (arr: boolean[]) => {
  // returns True when every element in array is false
  return !arr.every((v) => v === false);
};

export const loadingChecker = (appLoading: Loading) => {
  return arrloadingChecker(Object.values(appLoading));
};

interface Props {}

export const SelectorAppLoading: React.FC<Props> = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appLoading = useSelector((state: RootState) => state.appLoading);
  
  const loading = loadingChecker(appLoading);
  log.debug(loading);

  return <Fragment>{String(loading)}</Fragment>;
};
