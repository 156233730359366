import React, { useState } from "react";
import { Spin, Table, Card, Badge, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { differenceInDays, formatDuration, parseISO } from "date-fns";
import { CenteredVerHor } from "../style/CenteredVerHor";
import DatePicker from "../DatePicker";
import { RangeValue } from "rc-picker/lib/interface";
import { AxiosRequestConfig } from "axios";
import { PomoCategories } from "../../store/typesShared";
import { UsefulDates, mean, ranges } from "../../util/dateUtils";
import { createDateStringForParam } from "../../util/swrHelper";
import { usePomoQuerySWR, PomoQuery, BasePomoQuery } from "./pomoQuerySWR";
import log from "loglevel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { appGlobalUserSlice } from "../../store/appGlobalUser";

const { RangePicker } = DatePicker;

const columns: ColumnsType<BasePomoQuery> = [
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    defaultSortOrder: "ascend",
    sorter: (a: any, b: any) => a.rank - b.rank,
    // render: (text, record, index) => index,
  },
  {
    title: "Task ID",
    dataIndex: "id",
    key: "id",
    sorter: (a: any, b: any) => a.id - b.id,
  },
  {
    title: "Task",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Pomodoro",
    dataIndex: "pomodoro",
    key: "pomodoro",
    sorter: (a: any, b: any) => a.pomodoro - b.pomodoro,
  },
  {
    title: "Pomodoro per day",
    dataIndex: "pomodoro_avg_day",
    key: "pomodoro_avg_day",
    sorter: (a: any, b: any) => a.pomodoro_avg_day - b.pomodoro_avg_day,
  },
  {
    title: "Pomodoro type",
    dataIndex: "type",
    key: "type",
    sorter: (a: any, b: any) => a.type.localeCompare(b.type),
    // All types, look at Task model in django.
    filters: [
      {
        text: "Pomodoro",
        value: "Pomodoro",
      },
      {
        text: "Work",
        value: "Work",
      },
      {
        text: "Non-Pomodoro",
        value: "Non-Pomodoro",
      },
      {
        text: "Goal",
        value: "Goal",
      },
    ],
    onFilter: (value: any, row: any) => row.type.indexOf(value) === 0,
  },
  {
    title: "Categories",
    key: "categories",
    dataIndex: "categories",
    render: (categories) => (
      <>
        {categories.map((category: string) => {
          return (
            <Tag color={"geekblue"} key={category}>
              {category.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
];

// Specific to this data structure
const sumPomoByCategory = (
  pomoQueries: PomoQuery[],
  filterKey: PomoCategories
): number => {
  const filteredRecord = pomoQueries.filter(
    (record) => record.type === filterKey
  );
  const filteredRecord_PomoSum = filteredRecord.reduce(
    (accumulator, record) => accumulator + record.pomodoro,
    0
  ); // initialValue=0
  return filteredRecord_PomoSum;
};

const sumBadgeStyle = { backgroundColor: "#2db7f5" };
const avgBadgeStyle = { backgroundColor: "#52c41a" };

interface Props {}

export const PomoQueryTable: React.FC<Props> = () => {
  const usefulDates = new UsefulDates();

  // used for RangePicker. Original hook state, replaced by global Redux state.
  // const [startDate, setStartDate] = useState<Date>(usefulDates.today);
  // const [endDate, setEndDate] = useState<Date>(usefulDates.tomorrow);

  // used for RangePicker Redux Hook version
  const dispatch = useDispatch();
  const appGlobalUser = useSelector((state: RootState) => state.appGlobalUser);
  const startDate = parseISO(appGlobalUser.pomoQueryTableStartDate);
  const endDate = parseISO(appGlobalUser.pomoQueryTableEndDate);

  // used for making param to be send to API server
  const [config, setConfig] = useState<AxiosRequestConfig>({
    params: {
      start: createDateStringForParam(startDate),
      end: createDateStringForParam(endDate),
    },
  });

  // TODO: Add date to get. Today with + - some hours to capture whole day hopefully.
  // TODO: Add default time that can be changed.
  const { data, isLoading, isError } = usePomoQuerySWR(config);
  // const { results, next, previous, count} = data;

  const next = data?.next;
  const previous = data?.previous;
  const count = data?.count;
  const results = data?.results;
  const pomoQueries = results;

  log.debug("pomoQueries");
  log.debug(pomoQueries);

  const improveDataFromSource = (pomoQueries: PomoQuery[]) => {
    // Add in Rank and calculate pomodoro average
    const pomoQueriesUpdated = pomoQueries.map(
      (_pomoQuery: PomoQuery, index: number) => {
        const _recordUpdated = {
          ..._pomoQuery,
          rank: index + 1, // Add ranking which is already sorted on backend. Here just adding numbers.
          pomodoro_avg_day: mean(
            _pomoQuery.pomodoro,
            differenceInDaysOf_DateRange,
            1
          ),
        };
        return _recordUpdated;
      }
    );
    return pomoQueriesUpdated;
  };

  const rangePickerChange = (date: RangeValue<Date>, dateString: string[]) => {
    // Return if there is no date (will not be case from antd? but seems to need this for typescript)
    if (!date) {
      return;
    }

    // Take moments time object
    const _startDate = date[0] as Date;
    const _endDate = date[1] as Date;

    // Change UI
    // setStartDate(_startDate);
    // setEndDate(_endDate);
    // Date is not serializable. Redux recommends serializable data.
    dispatch(
      appGlobalUserSlice.actions.pomoQueryTableStartDate(
        _startDate.toISOString()
      )
    );
    dispatch(
      appGlobalUserSlice.actions.pomoQueryTableEndDate(_endDate.toISOString())
    );

    // Change to format backend (requires string of YYYY-MM-DD HH:MM[:ss[.uuuuuu]][TZ] format)
    setConfig({
      params: {
        start: createDateStringForParam(_startDate),
        end: createDateStringForParam(_endDate),
      },
    });
  };

  // Using built in loading for table
  // if (isLoading)
  //   return (
  //     <CenteredVerHor>
  //       <Spin size="large" />
  //       <div>Loading pomoQueries data...</div>
  //     </CenteredVerHor>
  //   );

  // if (isError)
  //   return (
  //     <CenteredVerHor>
  //       {/* Sadly doesn't actually freeze spinning but disapears */}
  //       <Spin size="large" spinning={false} />
  //       <div>Whaaat? There is an error</div>
  //     </CenteredVerHor>
  //   );

  ///
  // Process some display info before render.
  const differenceInDaysOf_DateRange = differenceInDays(endDate, startDate);
  log.debug(`differenceInDaysOf_DateRange: ${differenceInDaysOf_DateRange}`);

  const pomoQueries_processed =
    isLoading || !pomoQueries ? [] : improveDataFromSource(pomoQueries); // Add in average "pomodoro_per_day" and "rank"

  // Find "sums" (total) of pomodoro
  // Return 0 while it's loading or error (if error after data's loaded, it'll use previous data meaning keep number same)
  const sumPomo_Pomodoro = pomoQueries
    ? sumPomoByCategory(pomoQueries, "Pomodoro")
    : 0;
  const sumPomo_Work = pomoQueries ? sumPomoByCategory(pomoQueries, "Work") : 0;
  const sumPomo_Goal = pomoQueries ? sumPomoByCategory(pomoQueries, "Goal") : 0;
  const sumPomo_All = sumPomo_Pomodoro + sumPomo_Work + sumPomo_Goal;

  // Find "average per day" of pomodoro
  const avgPomo_Pomodoro =
    pomoQueries && mean(sumPomo_Pomodoro, differenceInDaysOf_DateRange, 1);
  const avgPomo_Work =
    pomoQueries && mean(sumPomo_Work, differenceInDaysOf_DateRange, 1);
  const avgPomo_Goal =
    pomoQueries && mean(sumPomo_Goal, differenceInDaysOf_DateRange, 1);
  const avgPomo_All =
    pomoQueries && mean(sumPomo_All, differenceInDaysOf_DateRange, 1);

  ///

  return (
    <div className="px-3">
      <h1 title="Filters date between start 4am to nextday 4am. Tasks falling between those times will be shown.">
        Your Pomodoro
      </h1>
      {/* Note for future: If I need more customization I will need to make flexbox with div instead of using Card from AntD. */}
      <div className="flex flex-col">
        <div className="flex flex-row">
          <Card title="Pomo" className="mx-1 w-32 text-center">
            <Badge
              title="Average"
              count={avgPomo_Pomodoro}
              style={avgBadgeStyle}
              showZero
              overflowCount={99999}
            />
            <Badge
              title="Total"
              count={sumPomo_Pomodoro}
              style={sumBadgeStyle}
              size="default"
              showZero
              overflowCount={99999}
            />
          </Card>
          <Card title="Work" className="mx-1 w-32 text-center">
            <Badge
              title="Average"
              count={avgPomo_Work}
              style={avgBadgeStyle}
              showZero
              overflowCount={99999}
            />
            <Badge
              title="Total"
              count={sumPomo_Work}
              style={sumBadgeStyle}
              size="default"
              showZero
              overflowCount={99999}
            />
          </Card>
          <Card title="Goal" className="mx-1 w-32 text-center">
            <Badge
              title="Average"
              count={avgPomo_Goal}
              style={avgBadgeStyle}
              showZero
              overflowCount={99999}
            />
            <Badge
              title="Total"
              count={sumPomo_Goal}
              style={sumBadgeStyle}
              size="default"
              showZero
              overflowCount={99999}
            />
          </Card>
          <div className="flex flex-row items-center mx-2">=</div>
          <Card title="All" className="mx-1 w-32 text-center bg-indigo-200">
            <Badge
              title="Average"
              count={avgPomo_All}
              style={avgBadgeStyle}
              showZero
              overflowCount={99999}
            />
            <Badge
              title="Total"
              count={sumPomo_All}
              style={sumBadgeStyle}
              size="default"
              showZero
              overflowCount={99999}
            />
          </Card>
        </div>
      </div>
      <div className="my-3">
        <Badge
          title={`This is ${formatDuration({
            days: differenceInDaysOf_DateRange,
          })}`}
          count={differenceInDaysOf_DateRange}
          style={{
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset",
          }}
          size="default"
          offset={[5, 0]}
        >
          <RangePicker
            // ranges={{
            //   Today: [usefulDates.today, usefulDates.tomorrow],
            // }}
            ranges={ranges()}
            showTime
            format="yyyy-MM-dd HH"
            value={[startDate, endDate]}
            onChange={(date, dateString) => rangePickerChange(date, dateString)}
          />
        </Badge>
      </div>
      <Table
        rowKey="id"
        loading={isLoading}
        dataSource={pomoQueries_processed} //pomoQueries is original
        columns={columns}
        // pagination={{
        //   position: ["bottomLeft", "topLeft"],
        //   pageSize: 100,
        //   showSizeChanger: false,
        //   hideOnSinglePage: true,
        //   simple: false,
        // }}
        pagination={{
          position: ["bottomLeft", "topLeft"],
          pageSize: 100,
          showSizeChanger: false,
          hideOnSinglePage: true,
          simple: false,
          total: count,
          onChange: (page, pageSize) => {
            setConfig({ ...config, params: { ...config.params, page: page } });
          },
        }}
        size="small"
        // bordered
      />
    </div>
  );
};
