import useSWR from "swr";
import { URLS } from "../../UrlConst";
import { DjangoPagination, PomoCategories } from "../../store/typesShared";
import { urlPlusPagination, fetcher } from "../../util/swrHelper";
import log from "loglevel";
import { AxiosRequestConfig } from "axios";

// "id": 1,
// "name": "Programming",
// "date_start": "2018-11-06",
// "timestamp": "2018-11-06T05:08:17",
// "description": "List of programming projects",
// "archived": false,
// "owner": 1,
// "categories": []

export interface BaseProject {
  [key: string]: any;

  id: number;
  name: string;
  date_start: string;
  timestamp: string;
  description: string;
  archived: boolean;
  categories: string[];
}

export interface Project extends BaseProject {
  owner?: number;
}

export interface ProjectResponse extends DjangoPagination {
  results: Project[];
}

export const useProject = (
  config?: AxiosRequestConfig,
): { data: ProjectResponse; isLoading: boolean; isError: boolean } => {

  const url = URLS.project;
  log.debug(url);

  log.debug(config);

  const { data, error } = useSWR([url, config], fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};
