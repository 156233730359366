import React, { useState } from "react";
import { Slider, Input, Button, Card, Row, Col, message } from 'antd';
import DatePicker from "../DatePicker";
import { LifeAddEmpty, lifeLabels } from "./lifeSWR";
import { createLife } from "../../util/swrCRUD";
import { format } from "date-fns";
import { LifePolarChart } from "./LifePolarChart";
import log from 'loglevel';

const { TextArea } = Input;

const defaultLife: LifeAddEmpty = {
  loveRelationship: 5,
  healthFitness: 5,
  careerEducation: 5,
  personalDevelopment: 5,
  family: 5,
  friends: 5,
  social: 5,
  funRecreation: 5,
  finance: 5,
  date: new Date(),
  comment: "",
};

interface Props {}

const sliderShared = { min: 1, max: 10 };

export const LifeAdd: React.FC<Props> = () => {
  const [life, setLife] = useState<LifeAddEmpty>(defaultLife);

  const renderLifeAddInputs = (LifeInputs: string[]) => {
    const lifeAddInputs = LifeInputs.map((input: string, i: number) => {
      return (
        <Col key={i} lg={6} md={8} sm={12} xs={24}>
          <div>
            {lifeLabels[input]} - {life[input]}
          </div>
          <Slider
            {...sliderShared}
            onChange={(val: number) => setLife({ ...life, [input]: val })}
            value={life[input]}
          />
        </Col>
      );
    });
    return lifeAddInputs;
  };

  return (
    <div className="flex flex-col lg:flex-row items-center">
      <div className="w-full lg:w-8/12">
        <Card className="">
          <Row>{renderLifeAddInputs(Object.keys(lifeLabels))}</Row>
          <Row>
            <TextArea
              rows={4}
              onChange={(e) => setLife({ ...life, comment: e.target.value })}
              value={life.comment}
            />
          </Row>
          <Row className="mt-3" justify="space-between">
            <DatePicker
              showTime
              format="YY-MM-DD h:mm:ss a"
              placeholder="Select Time"
              onChange={(val) => setLife({ ...life, date: val })}
              // onOk={this.onDatePickerStartOk}
              value={life.date}
              use12Hours
            />
            <Button
              type="dashed"
              disabled={life.date? false : true}  // rudimentary form validation through button and date
              onClick={() => {
                log.debug(`Submit data`);
                log.debug(life);
                // Fix date format
                const date = life.date as Date;
                const lifeCleaned = {
                  ...life,
                  date: format(date, "yyyy-MM-dd HH:mm:ss"),
                };
                // POST and after
                createLife(lifeCleaned)
                  .then((res) => {
                    message.success('Created Life Assessment');
                    log.info("Created Life Assessment");
                    log.debug(res);
                    setLife(defaultLife);
                  })
                  .catch((err) => {
                    message.error('Error - Life Assessment');
                    log.warn('Error - Life Assessment');
                    log.info(err);
                    log.info(err.response);
                  })
              }}
            >
              Submit
            </Button>
          </Row>
        </Card>
      </div>
      <div className="w-full lg:w-4/12 p-5">
        <LifePolarChart data={life} />
      </div>
    </div>
  );
};
