import React, { useState } from "react";
import {
  Slider,
  Input,
  Button,
  Card,
  Row,
  Col,
  message,
  AutoComplete,
  Tag,
  Spin,
} from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import DatePicker from "../DatePicker";
import { format } from "date-fns";
import {
  MoodAddEmpty,
  useGlobalFeelings,
  useGlobalMoodCategories,
} from "./moodSWR";
import { CenteredVerHor } from "../style/CenteredVerHor";
import { createMood, createFeeling, createMoodCategory } from "../../util/swrCRUD";
import log from "loglevel";
import { AxiosResponse } from "axios";

import "./moodAdd.css";

const { TextArea } = Input;

const defaultMood: MoodAddEmpty = {
  mood: 5,
  date: new Date(),
  comment: "",
  categories: [],
  feelings: [],
};

interface Props {}

const sliderShared = { min: 1, max: 10 };

const arrayRemoveElem = (array: string[], elemToRemove: string) => {
  // Remove element from array
  const newArray = array.filter((elem) => {
    return elem !== elemToRemove;
  });
  return newArray;
};

const arrayToAntDOptions = (array: string[]) => {
  /* For AntD Options.
  Converts array to [{value: "val"} {value: "val2"}...] */
  return array.map((val) => {
    return { value: val };
  });
};

export const MoodAdd: React.FC<Props> = () => {
  const {
    data: globalFeelings,
    isLoading: globalFeelingsIsLoading,
    isError: globalFeelingsIsError,
  } = useGlobalFeelings();

  const {
    data: globalMoodCategories,
    isLoading: globalMoodCategoriesIsLoading,
    isError: globalMoodCategoriesIsError,
  } = useGlobalMoodCategories();

  const [mood, setMood] = useState<MoodAddEmpty>(defaultMood);
  const [inputFeelings, setInputFeelings] = useState<string>("");
  const [inputCategories, setInputCategories] = useState<string>("");

  const renderStringArray = (
    array: string[],
    keyName: string,
    tagColor: string
  ) => {
    // Makes AntD Tags from Array of strings

    const arrayTags = array.map((elem) => {
      return (
        <Tag
          key={`${elem}`}
          color={tagColor}
          closable
          onClose={() =>
            setMood({ ...mood, [keyName]: arrayRemoveElem(array, elem) })
          }
        >
          {elem}
        </Tag>
      );
    });
    return arrayTags;
  };

  const handleStringArrayOnEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
    keyName: string,
    hookState: MoodAddEmpty, // State
    globalArray: string[], // All mood/feelings used (from backend)
    setInputFormFunc: React.Dispatch<React.SetStateAction<string>>, //Input form to clear after
    apiFunc: any,
  ) => {
    const eventInputVal = (e.target as HTMLTextAreaElement).value;
    const localArray = hookState[keyName];

    // Exit if user presses empty with no input or just spaces.
    if (eventInputVal.trim() === "") {
      return;
    }

    // User's input capitalized of first letter
    const eventInputVal_clean =
      eventInputVal.substr(0, 1).toUpperCase() + eventInputVal.substr(1);

    //
    // Validations
    //

    // Validation for if it's already added or exist in my current *input* list.
    // The value of the first element in the array that satisfies the provided testing function. Otherwise, undefined is returned.
    const searched_current_result = localArray.find((element: string) => {
      return element === eventInputVal_clean;
    });

    // Exit if it's already in list.
    if (searched_current_result !== undefined) {
      message.warning("Already added");
      return;
    }

    // Create new feeling/category if it doesn't exist in backend

    const searched_global_result = globalArray.find((element: string) => {
      return element === eventInputVal_clean;
    });

    //
    // Create - Backend? and locally
    //

    // If it's not in my global category database, ajax add first on backend, then sort out local state on success
    if (searched_global_result === undefined) {
      const data = { name: eventInputVal_clean };
      apiFunc(data)
        .then((res: AxiosResponse) => {
          message.success(`Added "${eventInputVal_clean}" to server`);
          log.info(`Added "${eventInputVal_clean}" to server`);
          log.debug(res);

          setMood({ ...mood, [keyName]: [...localArray, eventInputVal_clean] }); // Add to local state for uploading
          setInputFormFunc(''); // Clear input
        })
        .catch((err: any) => {
          message.error(`Error - Adding "${eventInputVal_clean}" to server`);
          log.warn(`Error - Adding "${eventInputVal_clean}" to server`);
          log.info(err);
          log.info(err.response);
        });
    } else {
      setMood({ ...mood, [keyName]: [...localArray, eventInputVal_clean] }); // Does not need to create on backend. Will add to user local state.
      setInputFormFunc(''); // Clear input
    }
  };

  if (globalFeelingsIsLoading || globalMoodCategoriesIsLoading)
    return (
      <CenteredVerHor>
        <Spin size="large" />
        <div>Loading feeling/category data...</div>
      </CenteredVerHor>
    );

  if (globalFeelingsIsError || globalMoodCategoriesIsError)
    return (
      <CenteredVerHor>
        {/* Sadly doesn't actually freeze spinning but disapears */}
        <Spin size="large" spinning={false} />
        <div>Whaaat? There is an error</div>
      </CenteredVerHor>
    );

  const { max, min } = sliderShared;
  const mid: number = Number(((max - min) / 2).toFixed(1));
  const preColorCls = mood.mood >= mid ? "" : "icon-wrapper-active";
  const nextColorCls = mood.mood >= mid ? "icon-wrapper-active" : "";

  return (
    <div>
      <Card>
        <div>Mood Now - {mood.mood}</div>
        <AutoComplete
          style={{ width: 200 }}
          options={arrayToAntDOptions(globalMoodCategories)}
          placeholder="Add a category"
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={(value) => setInputCategories(value)}
          value={inputCategories}
          className="mt-1"
        >
          <Input
            onPressEnter={(value) =>
              handleStringArrayOnEnter(
                value,
                "categories",
                mood,
                globalMoodCategories,
                setInputCategories,
                createMoodCategory
              )
            }
          />
        </AutoComplete>
        <div className="mt-1">
          {renderStringArray(mood.categories, "categories", "cyan")}
        </div>

        <div className="icon-wrapper">
          <FrownOutlined className={preColorCls} />
          <Slider
            {...sliderShared}
            // onChange={(val: number) => setInputMood(val)}
            onChange={(val: number) => setMood({ ...mood, mood: val })}
            value={mood.mood}
          />
          <SmileOutlined className={nextColorCls} />
        </div>

        <TextArea
          rows={4}
          onChange={(e) => setMood({ ...mood, comment: e.target.value })}
          value={mood.comment}
        />
        <AutoComplete
          style={{ width: 200 }}
          options={arrayToAntDOptions(globalFeelings)}
          placeholder="I feel..."
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={(value) => setInputFeelings(value)}
          value={inputFeelings}
          className="mt-1"
        >
          <Input
            onPressEnter={(value) =>
              handleStringArrayOnEnter(
                value,
                "feelings",
                mood,
                globalFeelings,
                setInputFeelings,
                createFeeling
              )
            }
          />
        </AutoComplete>
        <div className="mt-1">
          {renderStringArray(mood.feelings, "feelings", "geekblue")}
        </div>
        <div className="mt-3">
          <DatePicker
            showTime
            format="YY-MM-DD  h:mm:ss a"
            placeholder="Select Time"
            onChange={(val) => setMood({ ...mood, date: val })}
            value={mood.date}
            use12Hours
          />
          <Button
            type="dashed"
            style={{ float: "right" }}
            disabled={mood.date? false : true}  // rudimentary form validation through button and date
            onClick={() => {
              log.debug(`Submit data`);
              log.debug(mood);
              // Fix whatever needs to be
              const date = mood.date as Date;
              const moodCleaned = {
                ...mood,
                date: format(date, "yyyy-MM-dd HH:mm:ss"),
              };
              // POST and after
              createMood(moodCleaned)
                .then((res) => {
                  message.success('Created Mood');
                  log.info("Created Mood");
                  log.debug(res);
                  setMood(defaultMood);
                })
                .catch((err) => {
                  message.error('Error - Life Assessment');
                  log.warn('Error - Life Assessment');
                  log.info(err);
                  log.info(err.response);
                })
            }}
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
};
