import React from "react";
import { Link, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { Menu, Spin } from "antd";
import {
  HomeFilled,
  TableOutlined,
  TeamOutlined,
  SettingOutlined,
  LogoutOutlined,
  SmileOutlined,
  RadarChartOutlined,
  BorderOuterOutlined,
  BuildOutlined,
  ProjectOutlined,
  SolutionOutlined,
  SettingFilled
} from "@ant-design/icons";
import { loadingChecker } from "../../select/appLoading";
import { getActiveBoards } from "../kanban/utils";

const { SubMenu } = Menu;

interface Props {}

export const NavSider: React.FC<Props> = () => {
  const boardList = useSelector((state: RootState) => state.boardList);
  const appLoading = useSelector((state: RootState) => state.appLoading);

  const isLoading = loadingChecker(appLoading);

  // AntD does not support Fragment. Have to put it as array.
  const renderDevMenus = () => {
    if (process.env.NODE_ENV !== "production") {
      return [
        <Menu.Item key="/example/counter" icon={<BorderOuterOutlined />}>
          Counter Example<Link to="/example/counter"></Link>
        </Menu.Item>,
        <Menu.Item key="/example/pomotimer" icon={<BorderOuterOutlined />}>
          PomoTimer Example<Link to="/example/pomotimer"></Link>
        </Menu.Item>,
        <Menu.Item key="/example/kanban" icon={<BorderOuterOutlined />}>
          Kanban Example<Link to="/example/kanban"></Link>
        </Menu.Item>,
        <Menu.Item key="/dev" icon={<BorderOuterOutlined />}>
          Dev<Link to="/dev"></Link>
        </Menu.Item>,
        <Menu.Item key="/log" icon={<BorderOuterOutlined />}>
          Logger<Link to="/log"></Link>
        </Menu.Item>,

        <Menu.Divider key={"menu-divider-1"} />,
      ];
    }
  };

  const renderKanbanLinks = () => {
    const boardIDs_raw = getActiveBoards(boardList);
    const boardIDs = boardIDs_raw.filter((board) => !board.archived); // Only non-archived boards shown on side menu

    // TODO: Make user see 1, 2, 3 instead of real IDs. Maybe string?it
    // (May need function on front-end keeping it's own DB so url looks better)
    // (But maybe use longer string for board name, and it's unique to the person logged in.)
    let boardIDsComponents = boardIDs.map((data, index) => {
      let uiIndex = index + 1;
      return (
        <Menu.Item key={`/kanban/${data.id}`}>
          <Link to={`/kanban/${data.id}`}>{`${uiIndex} - ${data.name}`}</Link>
        </Menu.Item>
      );
    });
    return boardIDsComponents;
  };

  return (
    <Menu
      theme="dark"
      mode="inline" //selectedKeys={[location.pathname]}
      defaultOpenKeys={["kanban"]}
      defaultSelectedKeys={["/kanban"]}
    >
      <Menu.Item key="/" icon={<HomeFilled />}>
        Main<Link to="/"></Link>
      </Menu.Item>

      <SubMenu key="kanban" icon={<TeamOutlined />} title="Kanban Boards">
        {appLoading.boardList ? (
          <Menu.Item key={`x1`}>
            <Spin />
          </Menu.Item>
        ) : (
          renderKanbanLinks()
        )}
      </SubMenu>

      <Menu.Divider />

      <Menu.Item key="/record" icon={<TableOutlined />}>
        Your Record<Link to="/record"></Link>
      </Menu.Item>
      <Menu.Item key="/yourpomo" icon={<BuildOutlined />}>
        Your Pomodoro<Link to="/yourpomo"></Link>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="/projects" icon={<ProjectOutlined />}>
        Projects List<Link to="/projects"></Link>
      </Menu.Item>
      <Menu.Item key="/tasks" icon={<SolutionOutlined />}>
        Tasks List<Link to="/tasks"></Link>
      </Menu.Item>

      <Menu.Divider />

      {renderDevMenus()}

      <Menu.Item key="/mood" icon={<SmileOutlined />}>
        <Link to="/mood">Mood</Link>
      </Menu.Item>
      <Menu.Item key="/life" icon={<RadarChartOutlined />}>
        <Link to="/life">Life Assessment</Link>
      </Menu.Item>

      <Menu.Item key="/config" icon={<SettingOutlined />}>
        <Link to="/config">Config</Link>
      </Menu.Item>

      <Menu.Item key="/admin" icon={<SettingFilled />}>
        {/* Settings (Django)<Link to="/admin"></Link> */}
        <a
          href="https://api.kanxdoro.com/admin"
          target="_blank"
          rel="noopener noreferrer"
        >
          Settings (Django)
        </a>
      </Menu.Item>
      <Menu.Item key="/logout" icon={<LogoutOutlined />}>
        Logout<Link to="/logout"></Link>
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  );
};
