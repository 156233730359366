// In future add to check for the user
// Save setting in backend

export type PomoCategories = 'Work' | 'Pomodoro' | 'Non-Pomodoro' | 'Goal';

// FUTURE: This should be generated on backend (cached on frontend?). API endpoint where it returns boardname and what type.
// FUTURE: THIS IS HARD CODED VERSION TAILORED FOR ME ONLY
// Need config for "default" pomodoro type for given board, for a given user.
// Board Name: Default Value
export const POMODORO_TYPE:{ [key: string]: string} = {
  Daily: 'Pomodoro',
  Goals: 'Goal',
  Work: 'Work',
  'Non-Pomodoro': 'Non-Pomodoro'
}
